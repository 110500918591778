import { Input } from './text-input.styled';

export type TextInputProps = any;

export function TextInput(props: TextInputProps) {
  const { name, label, placeholder, ...rest } = props;
  const title = placeholder ? placeholder : label;

  return <Input {...rest} placeholder={title} id={name} name={name} />;
}

TextInput.defaultProps = {
  fullWidth: true,
  type: 'text',
};

export default TextInput;
