import styled, { css } from 'styled-components';

export const ArrowDown = styled.div<{$active?: boolean}>`
  position: absolute;
  top: 13px;
  right: 10px;
  width: 8px;
  height: 8px;
  border: 2px solid #6e7a86;
  border-width: 0 2px 2px 0;
  transition: 0.1s all ease-out;
  transform: rotate(45deg);
  ${(props) =>
    props.$active &&
    css`
      top: 15px;
      transform: rotate(-135deg);
      border-color: #fff;
    `}
`;

export const Container = styled.div`
  min-width: 120px;
  margin: 0;
  position: relative;
  &.priority {
    li {
      position: relative;
    }
    li::before {
      content: '';
      position: absolute;
      left: 2px;
      top: 50%;
      margin-top: -3.5px;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background-color: transparent;
    }
    li.critical::before {
      background-color: #ff4747;
    }
    li.info::before {
      background-color: #569056;
    }
    li.notification::before {
      background-color: #ff8322;
    }
  }
`;

export const Header = styled.div`
  font-size: 14px;
  padding: 9px 26px 9px 12px;
  background-color: #4f5761;
  color: #ffffff;
  height: 36px;
  border: solid 1px #bebebe;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 50%;
    margin-top: -3.5px;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background-color: transparent;
  }
  &.critical::before {
    background-color: #ff4747;
  }
  &.info::before {
    background-color: #569056;
  }
  &.notification::before {
    background-color: #ff8322;
  }
`;

export const ListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 118px;
  left: 1px;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #4f5761;
  border: 1px solid #666;
  border-top: 0;
  box-sizing: border-box;
  box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  margin: 1px 0 0;
`;

export const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 5px 2px 5px 12px;
  &.active{
    color: #fff;
    background-color: #2e95ef;
  }
  &:hover {
    color: #fff;
    background-color: #677480;
  }
`;
