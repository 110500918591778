import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { LayoutBase, Footer, Container } from 'libs/layout';
import { useAppSelector, USER_FEATURE_KEY, RootState } from 'app/store';
import { Content } from './layout-public.styled';

type ILayoutPublic = {
  children: JSX.Element;
  toLoginedPath: string;
  loginPath: string;
};

export function LayoutPublic(props: ILayoutPublic): JSX.Element {
  const { toLoginedPath, loginPath, children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPath = location.pathname === loginPath;
  const logined = useAppSelector((state: RootState) => state[USER_FEATURE_KEY].userLogined);
  //console.log('🚀 ~ LayoutPublic ~ logined:', logined);

  /**
   * navigate to logined page
   */
  useEffect(() => {
    if (logined && isLoginPath) {
      //console.log('🚀 navigate to logined', props.toLoginedPath);
      navigate(toLoginedPath);
    }
  }, [logined, isLoginPath]);

  return (
    <LayoutBase>
      <Container>
        <Content>{children}</Content>
        <Footer />
      </Container>
    </LayoutBase>
  );
}

export default LayoutPublic;
