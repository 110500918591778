import { useState } from 'react';

import { UserContent, Name, MenuItem, Divider, Menu, IconButton, ListItemIcon, ListItemText } from './user-menu.styled';

export type IUserMenu = {
  userName: string;
  menuIcon: JSX.Element;
  items: Array<IMenuItem>;
};

export function UserMenu(props: IUserMenu) {
  const { userName, menuIcon, items } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // click on item
  const click = (el: IMenuItem) => {
    el.action && el.action();
    handleClose();
  };

  // gen menu items
  const itemsEl = items.map((el, idx) => {
    if (el.divider) {
      return <Divider key={'menu' + idx} />;
    }
    return (
      <MenuItem onClick={() => click(el)} key={'menu' + idx}>
        {el.icon && <ListItemIcon>{el.icon}</ListItemIcon>}
        <ListItemText>{el.title}</ListItemText>
      </MenuItem>
    );
  });

  return (
    <UserContent>
      <Name>{userName}</Name>
      <div>
        <IconButton          
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {menuIcon}
        </IconButton>
        <Menu
          id="menu-appbar"          
        >
          {itemsEl}
        </Menu>
      </div>
    </UserContent>
  );
}

export default UserMenu;
