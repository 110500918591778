import styled from 'styled-components';

export const Input = styled.input<{ $mergedLeftInput?: boolean; $mergedRightInput?: boolean }>`
  color: white;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 12px;
  width: 100%;
  background-color: #4f5761;
  height: 36px;
  display: ${(props) => {
    if (props.$mergedLeftInput || props.$mergedRightInput) return 'inline-block';
  }};
  border: solid 1px #a3b0c0;
  border-right: ${(props) => {
    if (props.$mergedLeftInput) return 0;
  }};
  border-radius: ${(props) => {
    if (props.$mergedLeftInput) return '4px 0 0 4px';
    else if (props.$mergedRightInput) return '0 4px 4px 0';
    else return '4px';
  }};
`;

export const Content = styled.div`
  display: inline-block;
`;

export const DateInputBox = styled.div`
  position: relative;
  /* width: 160px; */
  max-width: 186px;
`;

export const CalendarBox = styled.div`
  position: absolute;
  z-index: 10;
  color: #000;
`;
