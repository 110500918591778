import styled from 'styled-components';

export const Content = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3) url(/images/loader.svg) no-repeat 50% 50%;
`;
