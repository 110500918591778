import { createSlice } from '@reduxjs/toolkit';

export const USER_FEATURE_KEY = 'user';

export interface IUserState {
  userLogined: boolean;
  user: any;
  admin: boolean;
  name?: string;
}

// initial state
export const initialUserState: IUserState = {
  userLogined: false,
  user: {},
  admin: false,
  name: '',
};

// slice
export const userSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState: initialUserState,
  reducers: {
    logout(state) {
      state.userLogined = false;
      state.user = initialUserState.user;
    },
    setUser(state, data: any) {
      state.userLogined = true;
      state.user = { ...data.payload.config };
      state.name = data.payload.name || data.payload.config.name;
    },
  },
});

// exports
export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;

export const getUserName = () => (state: { [USER_FEATURE_KEY]: IUserState }) => {
  return state[USER_FEATURE_KEY].name;
};

/**
 * Actions
 * -------
 *
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUser())
 * }, [dispatch]);
 * ```
 */
