import styled from 'styled-components';

export const Selector = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  display: flex;
  
  img {
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #000;
    width: auto;
    min-width: 92px;
    height: 60px;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s all ease-in;
    &.active {
      border: 1px solid #fff;
      opacity: 1;
      cursor: default;
    }
    &:hover {
      opacity: 1;
    }

    @media (max-width: 639px) {
      min-width: 50px;
      height: 40px;
    }
  }
`;
