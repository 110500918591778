import { useState, useRef } from 'react';
import moment from 'moment';
import Calendar from 'rc-calendar';
import ruRU from 'rc-calendar/lib/locale/ru_RU';
import TimePickerPanel from 'rc-time-picker/lib/Panel';

import { useOutsideClicker } from 'libs/hooks';
import { CalendarBox, DateInputBox, Content, Input } from './datetime-input.styled';

import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
// const timePickerElement2 = <TimePickerPanel defaultValue={moment('23:59:59', 'HH:mm')} />;

export type DateTimeInputProps = {
  name: string;
  value?: string;
  onChange?: (val: any) => void;
  mergedLeftInput?: boolean;
  mergedRightInput?: boolean;
  label?: string;
};

export const DateTimeInput = (props: DateTimeInputProps) => {
  const { value, onChange, name } = props;
  const [open, setOpen] = useState(false);

  /**
   * toggler open
   * @returns `
   */
  const toggle = () => setOpen((open) => !open);

  /**
   * close on click outside
   */
  const ref = useRef(null);
  useOutsideClicker(ref, () => {
    setOpen(false);
  });

  const getDisplayedValue = () => {
    return value
      ? moment(value)
          .format('lll')
          .replace(/[г., ]{4}/, '')
          .replace(/\./, '')
      : '';
  };

  const valueString = getDisplayedValue();

  return (
    <Content>
      <DateInputBox onClick={toggle} className="date-input-box">
        <Input
          disabled
          // defaultValue={getDisplayedValue()}
          value={valueString}
          onChange={(data) => onChange && onChange(data)}
          $mergedLeftInput={props.mergedLeftInput}
          $mergedRightInput={props.mergedRightInput}
          placeholder="01 авг 2000 12:30"
          name={name}
        />
      </DateInputBox>
      <div className="POPUP" ref={ref}>
        <CalendarBox style={{ display: open ? 'block' : 'none' }}>
          <Calendar
            value={moment(value)}
            selectedValue={moment(value)}
            timePicker={<TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm')} />}
            onSelect={(date) => {
              onChange && onChange(date);
            }}
            onChange={(date) => {
              onChange && onChange(date);
            }}
            locale={ruRU}
            className={'calendar'}
            showDateInput={false}
          />
        </CalendarBox>
      </div>
    </Content>
  );
};
