import styled from 'styled-components';

export const Content = styled.button`
  padding: 10px;
  border: 0;
  background: none;
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 18px;
  height: 18px;
  //z-index: 10000;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.15s ease-in;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 639px) {
    right: 5px;
    top: 3px;
  }
`;
