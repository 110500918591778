import { saveImg } from 'libs/common';
import { Save } from './video-player.styled';
import { Video } from './video';

export type IVideoMain = {
  cam: ICamUrl;
  rackId: string;
  createtime: string;
};

export const VideoMain = (props: IVideoMain) => {
  const { cam, rackId, createtime } = props;

  /**
   * dom id
   * @returns
   */
  const getActiveCamId = () => {
    return rackId + '_' + cam.id + '_main';
  };

  /**
   * save shot from video stream
   * @returns
   */
  const save = () => {
    saveImg(getActiveCamId());
  };

  const url = cam.url + '?ct=' + createtime;
  const id = getActiveCamId();

  return (
    <>
      <Save onClick={save} />
      {cam.url && <Video camId={cam.id} id={id} createtime={createtime} className='main' src={url} />}
    </>
  );
};
