import styled, { css } from 'styled-components';

export const Label = styled.label`
  opacity: 0.6;
  font-size: 13px;
  text-align: left;
  color: #fff;
  margin: 0 0 5px;
  display: block;
`;

export const Info = styled.span`
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: red;
  font-size: 0.7rem;
  margin: 3px 0 0;
`;

export const Content = styled.div<{ $error?: boolean; $size?: string }>`
  position: relative;

  ${(props) =>
    props.$size === 'big' &&
    css`
      ${Label} {
        opacity: 0.6;
        font-size: 13px;
        text-align: left;
        color: #fff;
        margin: 0 0 5px;
        display: block;
      }
    `}

  ${(props) =>
    props.$error &&
    css`
      input {
        border-color: red;
      }
    `}
`;
