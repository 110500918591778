import { ReactNode } from 'react';
import { StyledTitle } from './title.styled';

/* eslint-disable-next-line */
export interface ITitleProps {
  small?: boolean;
  children: ReactNode;
}

export function Title(props: ITitleProps) {
  return <StyledTitle small={props.small}>{props.children}</StyledTitle>;
}

export default Title;
