import styled, { css } from 'styled-components';

export const StyledTitle = styled.h1<{ small?: boolean }>`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.34;
  text-align: center;
  color: #231f20;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  margin: 0 0 30px;
  span {
    display: inline-block;
    vertical-align: baseline;
    line-height: 1.25;
    font-size: 16px;
    color: #777;
    margin-left: 20px;
  }
  @media (max-width: 639px) {
    font-size: 24px;
  }
  ${(props) =>
    props.small &&
    css`
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      justify-content: center;
      color: #231f20;
      margin: 0 0 30px;
    `}
`;
