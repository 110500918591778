import userService from '../user-service/user-service';

/**
 * board service
 * get objects from user config
 *
 * @summary store zones data
 */

class DeviceService {
  // devices (old)
  private nodesMap: IData = {};
  private nodes: Record<keyof IUserConfig, any> | undefined;
  private isOldData: boolean = false;

  /**
   * get objects
   * @param objects
   */
  public init = (objects: IUserConfig) => {
    const nodes: IData = {};

    this.isOldData = !objects.cameras;

    // cycle for types
    (Object.keys(objects) as (keyof IUserConfig)[]).forEach((type) => {
      const values = objects[type];
      if (values && Array.isArray(values)) {
        values.forEach((el) => {
          if (type === 'nodes') {
            el.racks.forEach((el1: any) => {
              el1.node = el.id;
              nodes[el1.id] = el1;
            });
          } else {
            nodes[el.id] = el;
          }
        });
      }
    });
    this.nodesMap = nodes;
    this.nodes = objects;
  };

  public getNodes = () => {
    return this.nodesMap;
  };

  public getNode = (id: string) => {
    return this.nodesMap[id];
  };

  public getZones = (type?: string) => {
    return this.nodes?.zones.filter((el: any) => (type ? el.zoneId.indexOf(type) !== -1 : true)) as Array<IZone>;
  };

  public getBoards = () => {
    return this.nodes?.boards as Array<IBoard>;
  };

  public getRackGroups = () => {
    return this.nodes?.nodes;
  };

  public getPayments = () => {
    return this.nodes?.payments;
  };

  public getExtensions = () => {
    return this.nodes?.extensions;
  };

  public getGooses = () => {
    return this.nodes?.gooses;
  };

  public getInnerId(id: string) {
    return this.nodesMap[id] ? this.nodesMap[id].caption : id;
  }

  public getNodeType(type: string, id?: string) {
    const types: Record<string, string> = {
      entry: 'Въездная стойка',
      zone: 'Промежуточная стойка',
      leave: 'Выездная стойка',
    };
    let name = '';
    switch (type) {
      case 'payment_terminal':
        name = 'АПП';
        break;
      case 'cashbox':
        name = 'Ручная касса';
        break;
      case 'server':
        name = 'Сервер';
        break;
      case 'goose':
        name = 'Стойка для прохода';
        break;
      default:
        name = 'Стойка';
        if (id) {
          const node = this.nodesMap[id];
          if (node.rack_type && types[node.rack_type]) {
            name = types[node.rack_type];
          }
        }
        break;
    }
    return name;
  }

  public getNodeTitle(type: string, id: string, caption?: string) {
    let out = this.getNodeType(type, id);
    if (out) {
      out += ' ';
    }
    if (caption) {
      out += caption;
    } else {
      out += type === 'cashbox' ? '' : this.getInnerId(id);
    }

    return out;
  }

  /**
   * is old mnemo version
   * @returns
   */
  public isOld = () => {
    return this.isOldData;
  };

  /**
   * get current mode
   * @returns
   */
  public getModeTitle = (mode: string) => {
    if (mode === 'emergency') {
      return 'блокированный';
    } else if (mode === 'offline') {
      return 'отключен';
    } else if (mode === 'regular') {
      return 'штатный';
    } else if (mode === 'test') {
      return 'сервисный';
    } else if (mode === 'free') {
      return 'свободный';
    }
  };

  /**
   * get default tab for rack app window
   * @param tabs
   * @param data
   * @returns
   */
  public getDefaultTab = (tabs: Array<any>, data: any) => {
    const withSignals = data?.withSignals;
    if (!withSignals) {
      return tabs[0]?.value;
    }

    const signalsIdx = tabs.findIndex((el) => el.value === 'signals');
    return tabs[signalsIdx]?.value;
  };

  /**
   * get tabs for device windows
   * @returns
   */
  public getTabs = (type: string = '') => {
    const tabs = [];
    if (!['server', 'cashbox'].includes(type)) {
      tabs.push({ title: 'Управление', value: 'control' });
    }
    tabs.push({ title: 'Сигналы', value: 'signals' });
    tabs.push({ title: 'История сигналов', value: 'history' });
    if (userService.haveRight('mnemo.rack_settings') && !['goose', 'server', 'cashbox'].includes(type)) {
      tabs.push({ title: 'Настройки', value: 'options' });
    }

    return tabs;
  };
}

export const deviceService = new DeviceService();
