/**
 * levels service
 * cams places
 *
 * @summary store zones data
 */

class LevelsService {
  // new
  private levels: Array<ILevel> = [];
  private levelsMap: Record<string, ILevel> = {};
  // private places: Array<IPlace> = [];
  private placesMap: Record<string, IPlace> = {};
  private placesFloorMap: Record<string, any> = {};
  private cams: Array<ICam> = [];
  private camsMap: Record<string, ICam> = {};
  private camsFloorMap: Record<string, any> = {};

  /**
   * get objects
   * @param objects
   */
  init = (objects: IUserConfig, levels: Array<any>) => {
    if (!objects.places || !objects.cameras || !levels) {
      return;
    }
    // console.log('🚀 ~ objects:', objects);

    // levels
    this.levels = levels.map((el) => {
      return { level: el.level.toString(), current: el.occupied, maximum: el.total };
    });
    this.levels.forEach((el) => {
      this.levelsMap[el.level] = el;
    });

    // cams
    const camsMap: Record<string, any> = {};
    const camsFloorMap: Record<string, any> = {};
    this.cams = objects.cameras.map((el: any) => {
      const out: ICam = {
        id: el.id,
        level: el.level,
        caption: el.caption,
        direction: el.direction,
        left: el.left,
        top: el.top,
        bottom: false,
        frame_uri: el.frame_uri,
        stream_uri: el.stream_uri,
        maxPlaces: 4,
      };

      // floor map
      if (!camsFloorMap[out.level]) {
        camsFloorMap[out.level] = {};
      }
      camsFloorMap[out.level][out.id] = out;

      // map
      camsMap[out.id] = out;

      // array
      return out;
    });
    this.camsMap = camsMap;
    this.camsFloorMap = camsFloorMap;

    // places
    const placesMap: Record<string, any> = {};
    const placesFloorMap: Record<string, any> = {};
    objects.places.forEach((el: any) => {
      // console.log('🚀 ~ objects.places.forEach ~ el:', el);
      if (camsMap[el.camera_id]) {
        const out: IPlace = {
          id: el.id,
          zoneId: el.zone_to,
          level: camsMap[el.camera_id].level,
          cameraId: el.camera_id,
          left: el.left,
          top: el.top,
          caption: el.caption,
          area_h: el.area_h,
          area_w: el.area_w,
          coordx: el.coordx,
          coordy: el.coordy,
        };
        // console.log('🚀 ~ objects.places.forEach ~ out:', out);

        // floor map
        if (!placesFloorMap[out.level]) {
          placesFloorMap[out.level] = {};
        }
        if (!placesFloorMap[out.level][out.cameraId]) {
          placesFloorMap[out.level][out.cameraId] = {};
        }
        placesFloorMap[out.level][out.cameraId][out.id] = out;

        // map
        placesMap[out.id] = out;
      }

      // array
      // return out;
    });
    this.placesMap = placesMap;
    this.placesFloorMap = placesFloorMap;

    // set coords
    this.setCoords();
  };

  /**
   * set coords manual
   */
  private setCoords() {
    Object.keys(this.camsFloorMap).forEach((floor) => {
      const colsCnt = 9;
      // const leftPad = 200;
      // const topPad = 50;
      // const rowHeight = 200;
      const colWidth = 1900 / colsCnt - 18;
      const placesColsCnt = 4;
      const placesPad = 80;
      // const placesRowHeight = 100;
      let row = 0;
      let col = 0;

      Object.keys(this.camsFloorMap[floor]).forEach((camId) => {
        const cam = this.camsFloorMap[floor][camId];
        // console.log('🚀 ~ Object.keys ~ cam:', cam);
        const y = parseInt(cam.top); //topPad + row * rowHeight;
        const x = parseInt(cam.left); //leftPad + col * colWidth;
        // this.camsFloorMap[floor][camId].top = y;
        // this.camsFloorMap[floor][camId].left = x;

        // places
        const places = this.getPlaces(floor, camId);
        // console.log('🚀 ~ floor, camId:', floor, camId, places);
        let placeRow = 0;
        let placeCol = 0;
        Object.keys(places).forEach((place) => {
          this.placesFloorMap[floor][camId][place].top = cam.direction === 'up' ? y - 100 : y + placesPad; // + placeRow * placesRowHeight;
          this.placesFloorMap[floor][camId][place].left = x + 47 - colWidth / 2 + placeCol * 45;

          // console.log('🚀 ~ Object.keys ~ this.placesFloorMap[floor][camId][place]:', this.placesFloorMap[floor][camId][place]);
          placeCol++;
          if (placeCol >= placesColsCnt) {
            placeCol = 0;
            placeRow++;
          }
        });

        col++;
        if (col >= colsCnt) {
          col = 0;
          row++;
        }
      });
    });
  }

  getPlaces = (level: string, camId: string) => {
    if (!this.placesFloorMap || !this.placesFloorMap[level] || !this.placesFloorMap[level][camId]) {
      return [];
    }
    return this.placesFloorMap[level][camId];
  };

  getPlace = (id: string) => {
    return this.placesMap[id];
  };

  getCams = (level: string) => {
    return this.camsFloorMap[level];
  };

  getCam = (id: string): ICam => {
    return this.camsMap[id];
  };

  setCam = (id: string, cam: ICam) => {
    this.camsMap[id].frame_uri = cam.frame_uri;
    this.camsMap[id].stream_uri = cam.stream_uri;
  };

  /**
   * get levels
   * @returns
   */
  public getLevels = (levelsStatus: ILevelsState) => {
    const defaultLevel = this.levels?.length ? this.levels[0].level : '1';
    const levels: Array<ILevel> = this.levels?.map((el: any) => {
      const status = levelsStatus[el.level];
      return { ...el, current: status?.current, maximum: status?.maximum };
    });
    return { defaultLevel, levels };
  };

  public setPlaceCoord = (placeId: string, data: any) => {
    const item = { ...this.placesMap[placeId], ...data };
    this.placesMap[placeId] = item;
  };
}

export const levelsService = new LevelsService();
