import { useState } from 'react';
import { useTransition } from 'transition-hook';

import { Icon, Content, Block } from './tooltip.styled';

export type TooltipProps = {
  info: any;
  children: any;
};

// standard
export const Tooltip = (props: TooltipProps) => {
  const { info, children } = props;
  const [show, setShow] = useState(false);
  const { stage, shouldMount } = useTransition(show, 150);
  // console.log('🚀 ~ Tooltip ~ stage:', stage, shouldMount);

  const initShow = () => {
    setShow(true);
  };

  const initHide = () => {
    setShow(false);
  };

  return (
    <Content>
      <Icon onMouseEnter={initShow} onMouseLeave={initHide}>
        {children}
      </Icon>
      {shouldMount && <Block className={stage}>{info}</Block>}
    </Content>
  );
};

Tooltip.defaultProps = {
  children: '?',
};
