import { CloseButton } from '../close-button/close-button';
import { Fade, Content, Header, Place } from './modal.styled';

export type IModal = {
  children: any;
  title: string;
  className?: string;
  close: () => void;
  withFade?: boolean;
  center?: boolean;
  slim?: boolean;
};

export const Modal = (props: IModal) => {
  const { withFade, children, title, className, center, slim } = props;

  const close = (event: any) => {
    event.preventDefault();
    props.close();
  };

  return (
    <>
      {withFade && <Fade onClick={close} />}
      <Place onClick={(event) => event.stopPropagation()} center={center} $slim={slim}>
        <Header className={className}>{title}</Header>
        <CloseButton onClick={close} />
        <Content>{children}</Content>
      </Place>
    </>
  );
};
