//import { NavLink } from 'react-router-dom';

import { ButtonStyled, Icon, Content } from './button.styled';

export type ButtonProps = {
  fullWidth?: boolean;
  color?: 'primary' | 'secondary' | 'black' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  icon?: any;
  onClick?: (event: any) => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
};

// standard
export const Button = (props: React.PropsWithChildren<ButtonProps>) => {
  const { children, icon, ...rest } = props;
  const items: any = [];
  if (icon) {
    if (typeof icon === 'string') {
      items.push(
        <Icon key={'icon1'}>
          <img src={'/images/buttons/' + icon} alt="" />
        </Icon>
      );
    } else {
      items.push(<Icon key={'icon1'}>{icon}</Icon>);
    }
  }
  items.push(<Content key={'content1'}>{children}</Content>);

  return <ButtonStyled {...rest}>{items}</ButtonStyled>;
};

Button.defaultProps = {
  fullWidth: false,
  color: 'secondary',
  size: 'sm',
  type: 'button',
};
