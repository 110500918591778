import { LocalStorage } from 'libs/common';

const storage = new LocalStorage('cams');

/**
 * get default cam
 * @param cams 
 * @param rackId 
 * @returns 
 */
export const getDefaultCam = (cams: Array<ICamUrl>, rackId: string) => {
  if (!cams) {
    return null;
  }
  let cam = storage.getItem(rackId);
  if (!cam) {
    cam = cams[0].id;
  }
  return cam;
};

/**
 * set default cam
 * @param rackId 
 * @param camId 
 */
export const setDefaultCam = (rackId: string, camId: string) => {
  storage.setItem(rackId, camId);
};
