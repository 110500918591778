import styled from 'styled-components';
//import { css } from '@emotion/react';

export const Version = styled.div`
  position: fixed;
  z-index: 9;
  bottom: 10px;
  left: 10px;
  font-size: 0.7rem;
  background: rgba(255, 255, 255, 0.1);
  color: #999;
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  @media (max-width: 959px) {
    display: none;
  }
`;
