import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import { links } from 'libs/data-config';
import { LayoutPublic, LayoutPrivate } from 'libs/layout';
import { Loader } from 'libs/ui';
import { PageLogin, PageMain, PageError } from './router-pages';

export function Router() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          {/* public */}
          <Route
            element={
              <LayoutPublic toLoginedPath={links.main} loginPath={links.login}>
                <Outlet />
              </LayoutPublic>
            }
          >
            <Route path={links.login} element={<PageLogin />} />
            <Route path="*" element={<PageError />} />
          </Route>
          {/* private */}
          <Route
            element={
              <LayoutPrivate toLoginPath={links.login}>
                <Outlet />
              </LayoutPrivate>
            }
          >
            <Route path={links.main} element={<PageMain />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
