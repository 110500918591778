import { Button, ButtonProps } from 'libs/ui';
import { Content } from './actions-row.styled';

export type ActionsRowProps = {
  title: string;
  color?: ButtonProps['color'];
  row: IData;
  action: (row: IData) => void;
  refresh?: () => void;
};

export const ActionsRow = (props: ActionsRowProps) => {
  const { color, title, row, action, refresh } = props;
  const other: any = {};
  if (color) {
    other.color = color;
  }
  return (
    <Content>
      <Button
        {...other}
        onClick={() => {
          action(row);
          refresh && refresh();
        }}
      >
        {title}
      </Button>
    </Content>
  );
};
