import styled, { css } from 'styled-components';

export const Fade = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const Header = styled.div`
  position: relative;
  cursor: grab;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  padding: 20px 0;
  @media (max-width: 639px) {
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const Place = styled.div<{ center?: boolean; $slim?: boolean }>`
  background: #242424;
  color: #828282;
  position: absolute;
  box-shadow: 0px 24px 72px rgba(0, 0, 0, 0.32);
  border-radius: 24px;
  color: #f5f3f3;
  padding: 0 20px 20px;
  max-width: 100%;
  min-width: 320px;
  display: inline-flex;
  flex-direction: column;

  ${(props) =>
    props.center &&
    css`
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10001;
    `}

  ${(props) =>
    props.$slim &&
    css`
      padding: 0;
      background: #393e44;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.58);
      border-radius: 0;

      ${Header} {
        background-color: #1c2024;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        color: #fff;
        position: relative;
        height: 38px;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: normal;
      }
      .close-button {
        right: 0;
        top: 0;
      }
    `}
    
  @media (max-width: 639px) {
    padding: 0 10px 10px;
    border-radius: 15px;
  }
`;

export const Content = styled.div``;
