import { useCallback } from 'react';
import { Row, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { TableRow } from './table-row';
import { TableHeader } from './table-header';
import { Content } from './table.styled';

// https://codesandbox.io/p/sandbox/github/tanstack/table/tree/main/examples/react/virtualized-rows?embed=1&file=%2Fsrc%2Fmain.tsx%3A16%2C1-16%2C43

export type TableProps = {
  columns: Array<ColumnDef<any>>;
  data: Array<any>;
  getNextData?: () => void;
  setData?: () => void;
  rowClick?: () => void;
  columnResizeMode: 'onChange' | 'onEnd';
  wide?: boolean;
  priorityColumn?: string;
  loading?: boolean;
};

export const Table = (props: TableProps) => {
  const { getNextData, loading, columns, data, rowClick, columnResizeMode, wide, priorityColumn } = props;
  // console.log('🚀 ~ Table ~ data:', data);

  const updateData = useCallback(() => {
    getNextData && getNextData();
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: false,
    columnResizeMode,
  });
  const rows = table.getRowModel().rows;

  // bottom tracker
  const scrollRef = useBottomScrollListener<HTMLDivElement>(updateData, {
    offset: 50,
    debounce: 150,
    // triggerOnNoScroll: false,
  });

  // virt  
  // const rowVirtualizer = useVirtualizer({
  //   count: rows.length,
  //   estimateSize: () => 40,
  //   getScrollElement: () => scrollRef.current,
  //   measureElement:
  //     typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
  //       ? (element) => element?.getBoundingClientRect().height
  //       : undefined,
  //   overscan: 5,
  // });

  // row params
  const rowParams = { rowClick, priorityColumn };
  const cl = loading ? 'loading' : '';
  const deltaOffset = table.getState().columnSizingInfo.deltaOffset;

  // {rowVirtualizer.getVirtualItems().map((virtualRow) => {
  //   const row = rows[virtualRow.index] as Row<any>;

  /*rows.map((row) => {*/
  return (
    <Content $wide={wide} className={cl} ref={scrollRef}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableHeader key={headerGroup.id} headerGroup={headerGroup} columnResizeMode={columnResizeMode} deltaOffset={deltaOffset} />
          ))}
        </thead>
        <tbody
          style={{
            // display: 'grid',
            // height: `${rowVirtualizer.getTotalSize()}px`,
            // position: 'relative',
          }}
        >
          {rows.map((row) => {
            return <TableRow key={row.id} row={row} {...rowParams} />;
          })}
        </tbody>
      </table>
    </Content>
  );
};

Table.defaultProps = {
  columnResizeMode: 'onChange',
};

export default Table;
