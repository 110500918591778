import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export const INTERFACE_FEATURE_KEY = 'interface';

import { deviceService, callService } from 'libs/services';

export interface IInterfaceState {
  loading: boolean;
  windows: Record<string, IWindow>;
  sounds: Array<any>;
  widgets: Array<any>;
}

export const initialInterfaceState: IInterfaceState = {
  loading: false,
  windows: {},
  sounds: [],
  widgets: [],
};

export const interfaceSlice = createSlice({
  name: INTERFACE_FEATURE_KEY,
  initialState: initialInterfaceState,
  reducers: {
    clean: (state) => {
      state.windows = initialInterfaceState.windows;
      state.widgets = initialInterfaceState.widgets;
      state.sounds = initialInterfaceState.sounds;
      console.log('🚀 ~ state:', state);
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    /**
     * show window
     * @param state
     * @param action
     */
    showWindow: (state, action: PayloadAction<IWindow>) => {
      state.windows[action.payload.id] = action.payload;
    },

    /**
     * hide window
     * @param state
     * @param action
     */
    hideWindow: (state, action: PayloadAction<{ id: string }>) => {
      delete state.windows[action.payload.id];
    },

    /**
     * play sound
     * @param state
     * @param action
     */
    playSound: (state, action: PayloadAction<string>) => {
      const newSounds = state.sounds.slice(0);
      if (newSounds.indexOf(action.payload) === -1) {
        newSounds.push(action.payload);
      }
      state.sounds = newSounds;
    },

    /**
     * stop sound
     * @param state
     * @param action
     */
    stopSound: (state, action: PayloadAction<string>) => {
      const newSounds = state.sounds.slice(0);
      const index = newSounds.indexOf(action.payload);
      if (index !== -1) {
        newSounds.splice(index, 1);
      }
      state.sounds = newSounds;
    },

    /**
     * add widget
     * @param state
     * @param action
     */
    addWidget: (state, action: PayloadAction<string>) => {
      const widgets = state.widgets.slice(0);
      if (widgets.indexOf(action.payload) === -1) {
        widgets.push(action.payload);
      }
      state.widgets = widgets;
    },

    /**
     * remove widget
     * @param state
     * @param action
     */
    removeWidget: (state, action: PayloadAction<string>) => {
      const widgets = state.widgets.slice(0);
      const index = widgets.indexOf(action.payload);
      if (index !== -1) {
        widgets.splice(index, 1);
      }
      state.widgets = widgets;
    },
  },
});

export const interfaceReducer = interfaceSlice.reducer;
export const interfaceActions = interfaceSlice.actions;
