//import { } from './radio.styled';

export type RadioProps = {
  label: string;
  name: string;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (event: any) => void;
  onBlur?: () => void;
  value: string;
  setValue?: any;
  withoutLabel?: boolean;
};

export function Radio(props: RadioProps) {
  const { label, ...rest } = props;
  delete rest.setValue;
  delete rest.withoutLabel;

  return (
    <label>
      <input type="radio" {...rest} />
      {label}
    </label>
  );
}

export default Radio;
