import { socket } from 'api';
import { userService } from '../user-service/user-service';

/**
 * boards service
 *
 * @summary
 */

class BoardsService {
  public updateBoardCount = (zoneId: string, data: any) => {
    socket.call('set_zone_status', {
      user_id: userService.getUserId(),
      zone_id: zoneId,
      current: data.current,
      comment: data.comment,
    });
  };
}

export const boardsService = new BoardsService();
