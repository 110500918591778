import { Version } from './footer.styled';

export const Footer = () => {
  return (
    <Version>
      {window.APP_VERSION}, build: {window.APP_BUILD}
    </Version>
  );
};

export default Footer;
