import projectInfo from '../../package.json';
import meta from '../metadata.json';

declare global {
  interface Window {
    config: IConfig;
    APP_VERSION: string;
    APP_BUILD: number;
  }
}

// common config
window.config.test = false;
window.config.loginMethod = 'client.login';
window.config.logoutMethod = 'client.logout';
window.APP_VERSION = projectInfo.version;
window.APP_BUILD = meta.build;

// test settings
const test =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test' ||
  process.env.REACT_APP_DEV === '1' ||
  window.location.href.indexOf('localhost') !== -1;
if (test) {
  window.config.debug = true;
  if (window.location.href.indexOf('localhost') !== -1) {
    window.config.localhost = true;

    // non direct
    window.config.apiUrl = 'https://svo.stage.caps.smprc.ru/api';
    window.config.socketUrl = 'https://svo.stage.caps.smprc.ru';

    // 2
    window.config.apiUrl = 'http://10.105.0.130:8080/api';
    window.config.socketUrl = 'http://10.105.0.130:8080';

    // stage krasn
    window.config.apiUrl = 'http://10.105.0.130:8081/api';
    window.config.socketUrl = 'http://10.105.0.130:8081';

    // 2 fix
    // window.config.apiUrl = 'http://10.105.0.130:8089/api';
    // window.config.socketUrl = 'http://10.105.0.130:8089';

    // window.config.apiUrl = 'https://mnemo.stockmann.stage.caps.smprc.ru/api';
    // window.config.socketUrl = 'https://mnemo.stockmann.stage.caps.smprc.ru';
    // window.config.imagesUrl = 'https://mnemo.stockmann.stage.caps.smprc.ru/photos/';

    // mock
    // window.config.apiUrl = 'http://localhost:3002/api';
    // window.config.socketUrl = 'http://localhost:3002';
  }

  console.log('env: test');
  console.log('API url: ' + window.config.apiUrl);
}

console.log('version: ' + projectInfo.version + ' / ' + meta.build);

export const config = window.config;
