import { useState, useEffect, useCallback } from 'react';

import { Place } from './video.styled';

const test = false;
export type IVideo = {
  createtime: string;
  camId: string;
  id: string;
  className: string;
  src: string;
  func?: (id: string) => void;
};

export const Video = (props: IVideo) => {
  const { id, camId, className, src, func, createtime } = props;
  const [ready, setReady] = useState(false);

  /**
   * update video src for test
   */
  const getImageSrc = useCallback(() => {
    return test ? '/images/test/' + camId + '.jpg?=ct=' + createtime : src;
  }, [camId, createtime, src]);

  /**
   * set active cam id
   */
  const setActiveCam = () => {
    func && func(camId);
  };

  /**
   * prepare dom
   */
  useEffect(() => {
    const el: any = document.getElementById(id);
    if (el) {
      el.src = getImageSrc();
    }

    return () => {
      if (el && el.src) {
        el.src = '';
      }
    };
  }, [getImageSrc, id]);

  const srcOk: string = getImageSrc();

  /**
   * start load
   */
  // const onLoadStart = () => {
  //   setReady(false);
  // };

  /**
   * end load
   */
  const onLoad = () => {
    setReady(true);
  };

  const cl = ready ? '' : ' loading';

  return (
    <Place className={cl}>
      <img
        //onLoadStart={onLoadStart}
        //onChange={onLoadStart}
        onLoad={onLoad}
        draggable="false"
        onDragStart={() => false}
        id={id}
        className={className}
        src={srcOk}
        onClick={setActiveCam}
        alt=""
      />
    </Place>
  );
};
