/**
 * call service
 *
 * @summary store calls
 */

class EventsService {
  /**
   * set urecognized
   * @param {*} items
   * @returns
   */
  /*
  setUnrecognaized = (items: any) => {
    if (!items.length) {
      this.props.setUnrecognaized(null);
      return;
    }

    // find old one
    if (items.length > 1) {
      items.sort((a, b) => {
        const d1 = moment(a.created_at);
        const d2 = moment(b.created_at);
        if (d1 === d2) {
          return 0;
        }
        const r = d1 - d2;
        return r;
      });
    }

    console.log('🚀 socket <- setUnrecognaized', items[0]);
    this.props.setUnrecognaized(items[0]);
  };
  */
  /**
   * is unrecognized
   * @param {*} data
   * @returns
   */
  /*
  isUnrecognized = (data: any) => {
    if (!userService.haveRight('mnemo.allow_payment_mean') || !userService.haveRight('mnemo.confirm_events')) {
      return false;
    }
    const test = data.data ? data.data.method : '';
    const isNotification = data.type === 'notification';

    if ((test === 'controller.vehicle_plate_event' || test === 'controller.vehicle_plate_secondtry') && isNotification) {
      return true;
    }
    return false;
  };
  */
}

export const eventsService = new EventsService();
