import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg{
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10px;
  }
  @media (max-width: 639px) {
    width: 100px;
    svg {
      right: 5px;
      
    }
  }
`;
