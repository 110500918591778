import styled, { css } from 'styled-components';

export const Content = styled.div<{ $wide?: boolean }>`
  max-height: 475px;
  min-height: 293px;
  height: inherit;
  overflow-y: auto;
  table {
    width: 100%;
    font-size: 13px;
    line-height: 1.3;

    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }
  th {
    position: relative;
    height: 35px;
    font-size: 12px;
    color: rgb(255, 255, 255);
    opacity: 0.6;
    font-weight: normal;
    font-size: 13px;
    vertical-align: middle;
  }
  td {
    position: relative;
    border-bottom: 1px solid rgba(222, 227, 231, 0.2);
    height: 40px;
    font-size: 13px;
    color: rgb(255, 255, 255);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  th:first-child,
  td:first-child {
    padding-left: 15px;
  }
  th:last-child,
  td:last-child {
    padding-right: 15px;
  }
  ${(props) =>
    props.$wide &&
    css`
      margin: 0 -15px;
    `}
`;
