/**
 * dictionaries service
 *
 * @summary store dictionaries
 */

const testReasonOfPass = false;

class DictService {
  private dicts: Record<string, IDict> = {};
  private withReasonOfPass = false;

  /**
   * get objects
   * @param objects
   */
  public init = (data: Array<any>) => {
    data.forEach((dict: any) => {
      // console.log('🚀 ~ Object.keys ~ dict.id:', dict.id);
      if (dict.id === 'reasonofpass') {
        this.withReasonOfPass = true;
        // console.log('🚀 ~ data.forEach ~ this.withReasonOfPass:', this.withReasonOfPass);
      }
      this.dicts[dict.id] = { items: dict.details, name: dict.id, title: dict.name, param: dict.param };
    });

    if (testReasonOfPass) {
      this.dicts['reasonofpass'] = {
        items: [
          { name: 'test1', value: 'test1' },
          { name: 'test2', value: 'test2' },
        ],
        name: 'reasonofpass',
        title: 'reasonofpass',
        param: 'reasonofpass',
      };
      this.withReasonOfPass = true;
    }
  };

  /**
   * get dict from store
   * @param name
   * @returns
   */
  public getDict(name: string) {
    return this.dicts[name];
  }

  /**
   * with reason of pass
   * @returns
   */
  public isWithReasonOfPass = () => this.withReasonOfPass;
}

export const dictService = new DictService();
