import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LayoutBase, Footer, Container } from 'libs/layout';
import { useAppSelector, USER_FEATURE_KEY, RootState } from 'app/store';
import Header from './header';

interface ILayoutPrivate {
  children: JSX.Element;
  toLoginPath: string;
}

export const LayoutPrivate = (props: ILayoutPrivate): JSX.Element => {
  const navigate = useNavigate();
  const logined = useAppSelector((state: RootState) => state[USER_FEATURE_KEY].userLogined);

  /**
   * check user logined
   */
  useEffect(() => {
    if (!logined) {
      navigate(props.toLoginPath);
    }
  }, [navigate, props.toLoginPath, logined]);

  return (
    <LayoutBase>
      <Container>
        <Header />
        {props.children}
        <Footer />
      </Container>
    </LayoutBase>
  );
};

export default LayoutPrivate;
