import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { userLoginMock } from './mock/user.mock';

type ILogic = {
  getError(error: any): string;
  unsetAuth(): void;
  packet: any;
  loginMethod: string;
  notify(title: string, message: string): void;
  storage: StorageInterface;
  debug: boolean | undefined;
};

export class Logic {
  lastMethod?: string;
  token: string;

  getError;
  unsetAuth;
  packet;
  loginMethod;
  notify;
  storage;
  debug;

  constructor({ getError, unsetAuth, packet, loginMethod, notify, storage, debug }: ILogic) {
    this.packet = packet;
    this.loginMethod = loginMethod;
    this.notify = notify;
    this.storage = storage;
    this.debug = debug;
    this.unsetAuth = unsetAuth;
    this.getError = getError;

    this.token = this.storage.getItem('token');
  }

  /**
   * logout user
   */
  logout = () => {
    this.storage.setItem('token', '');
    this.token = '';
    this.unsetAuth();
  };

  /**
   * get token
   * @returns
   */
  getToken(): string {
    return this.token;
  }

  /**
   * set auth token if needed
   * @param {*} req
   */
  setAuth = (req: AxiosRequestConfig) => {
    if (this.lastMethod !== this.loginMethod) {
      const token = this.getToken();
      if (token) {
        if (req.headers) {
          req.headers['authorization'] = 'apisession token="' + token + '"';
        }
      }
    }
    return req;
  };

  /**
   * prepare request
   * @param req
   * @returns
   */
  prepareRequest = (req: AxiosRequestConfig) => {
    this.lastMethod = req.url;

    // packet
    req.data = this.packet.createPacket(req.url, req.data);
    req.url = '';

    // auth
    return this.setAuth(req);
  };

  /**
   * prepare response
   * @param res
   * @returns
   */
  prepareResponse = (res: AxiosResponse) => {
    const isLoginMethod = this.lastMethod === this.loginMethod;
    const debugAuth = this.debug && isLoginMethod && false;
    const out = debugAuth
      ? {
          packet: userLoginMock,
          error: false,
        }
      : this.packet.decodePacket(res, isLoginMethod);

    if (out.error) {
      if (out.is401) {
        this.logout();
      }
      //this.prepareError()
      //console.log('🚀 ~ out.packet:', out.packet);
      return Promise.reject(this.getError(out.packet));
    } else {
      if (isLoginMethod) {
        //console.log('🚀 ~ isLoginMethod', out);
        this.storage.setItem('token', out.packet.token);
        this.token = out.packet.token;
      }
    }
    //console.log('🚀 ~ out', out);
    return out.packet;
  };

  /**
   * prepare error
   * @param error
   * @returns
   */
  prepareError = (error: any) => {
    console.log('🚀 ~ error:', error);
    let msg = this.packet.getError(error);
    if (!error.response || (error.response && error.response.status !== 400)) {
      this.notify('Ошибка соединения', msg);
    }
    //console.log('🚀 ~ prepareError:', msg);
    return Promise.reject(msg);
  };
}
