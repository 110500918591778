import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const EVENTS_FEATURE_KEY = 'events';

const test = false;
const testEvent = {
  created_at: '2022-12-16T19:07:12.624606+03:00',
  data: {
    method: 'controller.vehicle_plate_event',
    replace: null,
    stay_id: 'f2fa36ee899347c29fa9d7ac50678dd9',
    vehicle_plate: 'A999AB1',
    photo: 'c4a5a089925d577c5bec7776cd29fc5bbd1d36eb',
  },
  id: 'b38cc712-7d5b-11ed-b610-0e8b1da2deaa',
  message: 'ГРЗ отличается от недавно выехавшего на 1 символ',
  parking_id: '000',
  source: 'lane-1-1',
  source_name: 'Стойка 01',
  source_type: 'rack.lane',
  status: 'active',
  timestamp: '2022-12-16T19:07:11.621234+03:00',
  type: 'notification',
  updated_at: '2022-12-16T19:07:12.624616+03:00',
};

export interface IEventsState {
  events: Record<IEvent['source'], Record<IEvent['id'], IEvent>>;
  unrecognized: IUnrecEvent | null;
}

export const initialEventsState: IEventsState = {
  events: {},
  unrecognized: test ? testEvent : null,
};

export const eventsSlice = createSlice({
  name: EVENTS_FEATURE_KEY,
  initialState: initialEventsState,
  reducers: {
    setEvents: (state, action: PayloadAction<any>) => {
      state.events = {};
      action.payload.events.forEach((el: IEvent) => {
        if (!state.events[el.source]) {
          state.events[el.source] = {};
        }
        state.events[el.source][el.id] = el;
      });
    },
    setEvent: (state, action: PayloadAction<any>) => {
      const event = action.payload;
      if (!state.events[event.source]) {
        state.events[event.source] = {};
      }
      state.events[event.source][event.id] = event;
    },
    deleteEvent: (state, action: PayloadAction<{ id: string; source: string }>) => {
      const { source, id } = action.payload;
      delete state.events[source][id];
    },
    deleteEvents: (state, action: PayloadAction<string>) => {
      const source = action.payload;
      delete state.events[source];
    },
    setUnrecognized: (state, action: PayloadAction<any>) => {
      state.unrecognized = action.payload;
    },
  },
});

export const eventsReducer = eventsSlice.reducer;
export const eventsActions = eventsSlice.actions;
