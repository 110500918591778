import io from 'socket.io-client';

export class SocketApi {
  token = '';
  url = '';
  socket: any;
  userId: string = '';
  storage;
  constructor(url: string, storage: StorageInterface) {
    this.storage = storage;
    this.url = url;
  }

  /**
   * init socket
   * @returns
   */
  init(userId: string) {
    this.setToken(this.storage.getItem('token'));
    const socketParams = {
      // transports: ['websocket'],
      // withCredentials: true,
      withCredentials: false,
      reconnection: true,
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: 'Bearer ' + this.token,
          },
        },
      },
    };
    this.socket = io(this.url, socketParams);
    // console.log('🚀 ~ socket init ~ this.url:', this.url, this.socket);
    this.userId = userId;

    // reconnect
    this.socket.on('connect', () => {
      console.log('socket: connect');
    });

    // disconnect
    this.socket.on('disconnect', (msg: any) => {
      console.log('socket: disconnect', msg);
    });

    // error
    this.socket.on('error', (msg: any) => {
      console.log('socket: error', msg);
    });

    return this.socket;
  }

  /**
   * deinit
   */
  deInit() {
    // console.log('🚀 ~ socket deinit');
    if (this.socket.connected) {
      this.socket.close();
      // this.socket = null;
    }
  }

  test() {
    this.call('ping');
  }

  setToken(token: string) {
    this.token = token;
  }

  call(method: string, params: any = {}, callback: any = null) {
    console.log('🚀 socket call:', method, params);
    if (params.token === undefined) {
      params.token = this.token;
    }
    if (!params.token) {
      params.token = this.storage.getItem('token');
      this.setToken(params.token);
    }

    if (!params.token) {
      throw new Error('socket: no token');
    }

    // user
    params.user_id = this.userId;

    this.socket.emit(method, params);
    if (callback) {
      callback();
    }
  }

  on(type: string, callback: (data?: any) => void) {
    return this.socket.on(type, callback);
  }

  off(type: string, callback: (data?: any) => void) {
    return this.socket.off(type, callback);
  }
}
