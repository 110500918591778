export const userLoginMock = {
  token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyLCJsb2dnZWRfaW4iOjE2Nzk0MjU4NDkuNjE1NTQzNn0.RpvB8VkYvvmOuVXqq0QlDgTKc3nN4MYh_esfcrBsEN0',
  user_info: {
    id: 2,
    role_id: 3,
    name: 'Администратор',
    email: null,
    phone: null,
    username: 'admin',
    role: 'Администратор',
    is_active: true,
    sip_number: '100',
    created_at: '2020-08-20T12:52:18.545786+03:00',
    active: true,
  },
}