import styled, { css } from 'styled-components';

export const WindowContent = styled.div<{ $nopad?: boolean; $minH?: string }>`
  padding: 15px 15px 0;
  background-color: #393e44;
  min-height: ${(props) => (props.$minH ? props.$minH : '586px')};
  ${(props) =>
    props.$nopad &&
    css`
      padding: 0;
    `}
`;
