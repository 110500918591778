import styled from 'styled-components';

export const StyledFrontendPage = styled.div`
  max-width: 480px;
  width: 100%;
`;
export const Logo = styled.div`
  margin: 0 0 2rem;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  /*
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  */
  padding: 2rem 2.5rem 2.5rem;
  background: #fff;
  margin: 0 0 2rem;
  border-radius: 0.5rem;
`;

export const BottomLink = styled.div`
  margin: 0 0 1rem;
  a {
    color: #3b6aaa;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const Bottom = styled.div``;
