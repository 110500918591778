const errors: Record<string, string> = {
  'invalid password': 'Неверный пароль',
  'Forbidden: invalid password': 'Неверный пароль',
  'Invalid params: user not found': 'Пользователь не найден',
  'user not found': 'Пользователь не найден',
  'duplicate mean_id': 'БСК с таким номером уже существует',
  ERR_NETWORK: 'Сетевая ошибка',
  ECONNABORTED: 'Соединение прекращено',
  404: 'Страница не найдена',
};

export const getRpcError = (msg: string) => {
  const out = errors[msg] ? errors[msg] : msg;
  return out;
};
