/**
 * window service
 *
 * @summary store windows data
 */

class WindowService {

  lastZIndex: number = 1000;

  updateLastZIndex = () => {
    this.lastZIndex++;
    return this.lastZIndex;
  };
}

export const windowService = new WindowService();
