import styled from 'styled-components';

export const Place = styled.div``;

export const Value = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #bbb;
  b {
    transition: all .2s ease-in;
    font-weight: normal;
  }
  &.odd b{
    opacity: 0;
  }
`;
