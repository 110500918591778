import { config } from 'app/config';
import { notify, LocalStorage } from 'libs/common';

import { Transport } from './transport';
import { PacketJSONRPC } from './packet-jsonrpc';
import { Logic } from './logic';
import { getRpcError } from './func';

const packet = new PacketJSONRPC({ getErrorMessage: getRpcError });
const logic = new Logic({
  notify,
  storage: new LocalStorage('api'),
  packet,
  loginMethod: 'auth.login',
  debug: false,
  getError: getRpcError,
  unsetAuth: () => {
    //window.location.assign('/');
  },
});
export const api = new Transport({ apiUrl: config.apiUrl, timeout: config.timeout, logic });

export default api;
