import { useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

import { config } from './config';
import { notify } from 'libs/common';
import { Loader } from 'libs/ui';
import { userService } from 'libs/services';
import { useAppSelector, USER_FEATURE_KEY, RootState } from 'app/store';
import { AppSocket } from './app-socket';
import Router from './router/router';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${config.bgColor};
  }
`;

const App = () => {
  const [checked, setChecked] = useState(false);
  const logined = useAppSelector((state: RootState) => state[USER_FEATURE_KEY].userLogined);

  /**
   * check user token
   */
  useEffect(() => {
    userService
      .checkToken()
      .then(() => {
        setChecked(true);
      })
      .catch((error: string) => {
        notify('Ошибка сети', error);
        setChecked(true);
      });
  }, []);

  /**
   * checked ?
   */
  if (!checked) {
    return <Loader />;
  }

  // console.log('🚀 ~ App ~ logined checked:', logined, checked);

  return (
    <>
      <GlobalStyle />
      {logined && <AppSocket />}
      <Router />
    </>
  );
  //return logined ? <AppLogined /> : <Auth />;
};

export default App;
