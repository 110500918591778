import { useState } from 'react';
import moment from 'moment';

import { findArrayItem } from 'libs/common';
import { Refresh, Place, Videos } from './video-player.styled';
import { VideoMain } from './video-main';
import { VideoSelector } from './video-selector';
import { useWindowSize } from 'libs/hooks';
import { getDefaultCam, setDefaultCam } from './video-player.func';

export type VideoPlayerProps = {
  cams: Array<ICamUrl>;
  rackId: string;
  height?: string;
};

export const VideoPlayer = (props: VideoPlayerProps) => {
  const { cams, rackId, height } = props;
  if (!cams || !cams.length) {
    return null;
  }
  const [createtime, setCreatetime] = useState(moment().unix());
  const [camId, setCamId] = useState(getDefaultCam(cams, rackId));
  const size = useWindowSize();

  const selectCam = (camId: string) => {
    setCamId(camId);
    setDefaultCam(rackId, camId);
  };

  if (!size.width) {
    return null;
  }

  const refresh = () => {
    setCreatetime(moment().unix());
  };

  const h = size.width < 640 ? '197px' : height ? height : '310px';
  const cam = findArrayItem(cams, camId);
  if (!cam) {
    setCamId(cams[0].id);
  }

  if (!cam) {
    return null;
  }
  // console.log('🚀 ~ VideoPlayer ~ cams:', cam, camId);

  return (
    <Place height={h}>
      <Refresh onClick={refresh} />
      <Videos>
        <VideoMain createtime={createtime.toString()} rackId={rackId} cam={cam} />
        <VideoSelector createtime={createtime.toString()} rackId={rackId} cams={cams} selectedCamId={camId} selectCam={selectCam} />
      </Videos>
    </Place>
  );
};
