import { CloseIcon } from 'libs/icons';
import { Content } from './close-button.styled';

export type CloseButtonProps = {
  onClick?: (event: any) => void;
  disabled?: boolean;
  className?: string;
};

// standard
export const CloseButton = (props: CloseButtonProps) => {
  const { className, ...rest } = props;
  const cl = 'close-button ' + className;
  return (
    <Content {...rest} className={cl}>
      <CloseIcon />
    </Content>
  );
};
