import { useState, useEffect } from 'react';

import { socket } from 'api';
import { callService, deviceService } from 'libs/services';
import { useAppDispatch, useAppSelector, RootState, CALLS_FEATURE_KEY, interfaceActions, callsActions } from 'app/store';
import { Content, BottomPanel, CallStatus, Dot, DropCall, TopPanel, TypeIcon } from './active-call.styled';

export const ActiveCall = () => {
  const calls = useAppSelector((state: RootState) => state[CALLS_FEATURE_KEY].calls);
  const dispatch = useAppDispatch();

  const [activeCalls, setActiveCalls] = useState<Array<any>>([]);
  const [status, setStatus] = useState<number>(0);
  let timerInterval: any = null;

  useEffect(() => {
    findActiveCalls();
    setCallTimer();
    window.addEventListener('beforeunload', dropCall);

    return () => {
      if (timerInterval) {
        window.clearInterval(timerInterval);
      }
      window.onclose = dropCall;
    };
  }, []);

  /**
   * find active calls
   */
  const findActiveCalls = () => {
    const active = [];
    const activeCalls = callService.getActiveCalls();
    for (let key in activeCalls) {
      if (calls[key]) {
        active.push(calls[key]);
      }
    }
    setActiveCalls(active);
  };

  /**
   * drop call
   */
  const dropCall = () => {
    if (timerInterval) {
      window.clearInterval(timerInterval);
      timerInterval = null;
    }

    activeCalls.forEach((item) => {
      socket.call('call_end', item);
    });

    setStatus(0);
    setTimeout(() => {
      activeCalls.forEach((item) => {
        dispatch(callsActions.dropCall(item.source_inner_id));
      });
      dispatch(interfaceActions.removeWidget('activeCall'));
    }, 1000);
  };

  /**
   * get source type
   * @returns
   */
  const getSourceType = () => {
    let paymentTerminalFinded = false;
    let rackFinded = false;
    activeCalls.forEach((item) => {
      if (item.source_inner_id.length === 5) {
        paymentTerminalFinded = true;
      } else {
        rackFinded = true;
      }
    });
    if (paymentTerminalFinded && rackFinded) {
      return <img src="/images/payment-and-rack.png" alt="" />;
    } else if (paymentTerminalFinded) {
      return <img src="/images/payment_terminal.png" alt="" />;
    } else {
      return <img src="/images/single_rack.png" alt="" />;
    }
  };

  /**
   * format time
   * @param minutes
   * @param seconds
   * @returns
   */
  const formatTime = (minutes: number, seconds: number) => {
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    const secondsString = seconds < 10 ? '0' + seconds : seconds;
    return minutesString + ':' + secondsString;
  };

  /**
   * get call status
   * @returns
   */
  const getCallStatus = () => {
    if (!timerInterval) {
      return 'Конец разговора';
    }
    let seconds = status;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds - minutes * 60;
    return formatTime(minutes, seconds);
  };

  const setCallTimer = () => {
    timerInterval = window.setInterval(() => {
      setStatus((status) => status + 1);
    }, 1000);
  };

  const getNodeName = (id: string) => {
    const devices = deviceService.getNodes();
    const types: Record<string, string> = {
      cashbox: 'Касса',
      single_rack: 'Стойка',
      payment_terminal: 'АПП',
    };
    if (!devices[id]) {
      return { title: '' + id, payment: false };
    }
    const item = devices[id];
    let out = types[item.type] ? types[item.type] + ' ' : '';
    out += item.caption;
    return { title: out, payment: item.type === 'payment_terminal' };
  };

  /**
   * get source number
   * @returns 
   */
  const getSourceNumber = () => {
    let paymentTerminalIds: Array<any> = [];
    let rackIds: Array<any> = [];
    activeCalls.forEach((item) => {
      const node = getNodeName(item.source_inner_id);
      if (node.payment) {
        paymentTerminalIds.push(node.title);
      } else {
        rackIds.push(node.title);
      }
    });
    return (
      <>
        {paymentTerminalIds.length ? (
          <p>
            <Dot color={'#4b7153'} />
            {paymentTerminalIds.join(', ')}
          </p>
        ) : null}
        {rackIds.length ? (
          <p>
            <Dot color={'#7e7343'} />
            {rackIds.join(', ')}
          </p>
        ) : null}
      </>
    );
  };

  return (
    <Content>
      <TopPanel>
        <TypeIcon>{getSourceType()}</TypeIcon>
        <CallStatus>{getCallStatus()}</CallStatus>
        <DropCall onClick={dropCall}>
          <img src="/images/audio-2.png" alt="" />
        </DropCall>
      </TopPanel>
      <BottomPanel>{getSourceNumber()}</BottomPanel>
    </Content>
  );
};
