import { lazy } from 'react';

// main page
export const PageMain = lazy(() =>
  import('app/pages/main').then(({ PageMain }) => ({
    default: PageMain,
  }))
);

export const PageLogin = lazy(() =>
  import('app/pages/login').then(({ PageLogin }) => ({
    default: PageLogin,
  }))
);

export const PageError = lazy(() =>
  import('app/pages/errors').then(({ PageError }) => ({
    default: PageError,
  }))
);
