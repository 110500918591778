import { getScale } from 'libs/common';
import { useWindowSize } from 'libs/hooks';
import { useAppDispatch, interfaceActions } from 'app/store';
import { Button, ButtonProps } from '../button/button';

export type WindowButtonProps = ButtonProps & {
  window: string;
  id: string;
  data?: Record<string, any>;
  className?: string;
  withScale?: boolean;
};

// standard
export const WindowButton = (props: React.PropsWithChildren<WindowButtonProps>) => {
  const { id, window, data, children, withScale, ...rest } = props;
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const scale = getScale(size);

  const getXY = (oElement: any) => {
    let x = 0,
      y = 0;
    while (oElement != null) {
      x += oElement.offsetLeft;
      y += oElement.offsetTop;
      oElement = oElement.offsetParent;
    }
    return { x, y };
  };

  const show = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = event; // clientX, clientY,
    // console.log('🚀 ~ show ~ currentTarget:', currentTarget.offsetLeft, currentTarget.offsetTop + currentTarget.clientHeight);
    const offset = getXY(currentTarget);
    const left = offset.x - 40;
    const top = offset.y + currentTarget.clientHeight + (withScale ? 40 : 20);
    const coords = { left, top };
    if (withScale) {
      coords.left *= scale;
      coords.top *= scale;
    }
    dispatch(interfaceActions.showWindow({ id: id, type: window, data, coords }));
    console.log('🚀 ~ show ~ coords:', coords);
  };

  return (
    <Button {...rest} onClick={show}>
      {children}
    </Button>
  );
};
