import { useEffect } from 'react';

import { RefreshFilter } from 'libs/ui';
import { useStateStorage } from './hook-use-state-storage';

export type UseFilterRefresherParams = {
  name: string;
  def: boolean;
  action: () => void;
  storage: StorageInterface;
  timeout?: number;
  activeAction?: (active: boolean) => void;
};

export const useFilterRefresher = (params: UseFilterRefresherParams) => {
  const defaultParams = {
    timeout: 5000,
  };
  const { name, def, action, activeAction, storage, timeout } = { ...defaultParams, ...params };
  const [active, setActive] = useStateStorage(name, def, storage);

  /**
   * change checkbox
   * @param {*} event
   */
  const change = (event: any) => {
    setActive(event.target.checked);
    activeAction && activeAction(event.target.checked);
  };

  /**
   * action
   */
  useEffect(() => {
    let timer: any;
    if (active) {
      timer = setInterval(() => {
        action();
      }, timeout);
      action();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [active]);

  const input = <RefreshFilter value={active} change={change} />;

  return [active, input];
};
