import { flexRender, HeaderGroup, ColumnResizeMode } from '@tanstack/react-table';

export type TableHeaderProps = {
  headerGroup: HeaderGroup<any>;
  columnResizeMode?: ColumnResizeMode;
  deltaOffset: number | null;
};

export const TableHeader = ({ headerGroup, columnResizeMode, deltaOffset }: TableHeaderProps) => {
  return (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <th
          key={header.id}
          style={{
            // display: 'flex',
            width: header.getSize(),
          }}
        >
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          <div
            {...{
              onMouseDown: header.getResizeHandler(),
              onTouchStart: header.getResizeHandler(),
              className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
              style: {
                transform: columnResizeMode === 'onEnd' && header.column.getIsResizing() ? `translateX(${deltaOffset}px)` : '',
              },
            }}
          />
        </th>
      ))}
    </tr>
  );
};
