import { useState } from 'react';

import { Button, ButtonProps } from '../button/button';

export type BlockedButtonProps = ButtonProps & {
  timeout: number;
};

// standard
export const BlockedButton = (props: React.PropsWithChildren<BlockedButtonProps>) => {
  const { timeout, children, onClick, ...rest } = props;
  const [blocked, setBlocked] = useState(false);

  /**
   * on click
   */
  const click = (event: any) => {
    if (!onClick) {
      return;
    }
    if (blocked) {
      return;
    }
    setBlocked(true);

    onClick(event);

    setTimeout(() => {
      setBlocked(false);
    }, 10000);
  };

  return (
    <Button {...rest} className={blocked ? 'loading' : ''} onClick={click}>
      {children}
    </Button>
  );
};

BlockedButton.defaultProps = {
  timeout: 1000,
  fullWidth: false,
  color: 'secondary',
  lg: false,
  type: 'button',
};
