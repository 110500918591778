import { useEffect } from 'react';

import { socket } from 'api';
import { notify } from 'libs/common';
import { userService, dictService } from 'libs/services';
import { useAppDispatch, boardsActions, levelsActions, eventsActions, callsActions } from 'app/store';
import { prepareZones, prepareCam, prepareVideoUrls } from './app-socket.func';

let appSocket: any;

export const AppSocket = () => {
  // dispatcher
  const dispatch = useAppDispatch();

  // init socket
  useEffect(() => {
    // create socket
    appSocket = socket.init(userService.getUserId());

    // init events
    appSocket.on('user_error', userLogout);
    appSocket.on('get_state', setCam);
    appSocket.on('get_video_urls', setVideoUrls);
    //
    appSocket.on('dictionaries.list', setDictionaries);
    appSocket.on('get_zones_status', setBoards);
    appSocket.on('set_zone_status', setBoard);
    appSocket.on('get_active_events', setEvents);
    appSocket.on('get_all_calls', setCalls);
    appSocket.on('signals.set', setEvent);
    appSocket.on('add_event', addEvent);

    // old
    socket.call('dictionaries.list');
    socket.call('get_zones_status');
    socket.call('get_active_events');
    socket.call('get_all_calls');

    return () => {
      // reset
      appSocket.off('user_error', userLogout);
      appSocket.off('get_state', setCam);
      appSocket.off('get_video_urls', setVideoUrls);

      // old
      appSocket.off('dictionaries.list', setDictionaries);
      appSocket.off('get_zones_status', setBoards);
      appSocket.off('set_zone_status', setBoard);
      appSocket.off('get_active_events', setEvents);
      appSocket.off('get_all_calls', setCalls);
      appSocket.off('signals.set', setEvent);
      appSocket.off('add_event', addEvent);

      // close socket
      socket.deInit();
      appSocket = null;
    };
  }, []);

  /* get results
   ------------------------------------------*/

  /**
   * set dictionaries
   * @param data
   */
  const setDictionaries = (data: any) => {
    dictService.init(data);
  };

  /**
   * set zones
   * @param data
   */
  const setBoards = (data: any) => {
    if (data.levels) {
      dispatch(levelsActions.setZones({ occupied: data.occupied, zones: prepareZones(data.zones), levels: data.levels }));
    } else {
      dispatch(boardsActions.setStatus({ zones: prepareZones(data.zones) }));
    }
  };

  /**
   * set zone
   * @param data
   */
  const setBoard = (data: any) => {
    const zone = prepareZones([data.zone]);
    dispatch(boardsActions.setBoardStatus({ zone: zone[0] }));
  };

  /**
   * set events
   * @param data
   */
  const setEvents = (data: any) => {
    dispatch(eventsActions.setEvents(data));
  };

  /**
   * set event
   * @param data
   */
  const setEvent = (data: any) => {
    dispatch(eventsActions.setEvent(data));
  };

  /**
   * add event
   * @param data
   */
  const addEvent = (data: any) => {
    if (data.status === 'processed') {
      return;
    }
    dispatch(eventsActions.setEvent(data));
  };

  /**
   * set calls
   * @param data
   */
  const setCalls = (data: any) => {
    // console.log('🚀 ~ setCalls ~ data:', data);
    dispatch(callsActions.setCalls(data.calls));
  };

  /**
   * set cam state
   * @param data
   */
  const setCam = (data: any) => {
    //console.log('🚀 ~ setBoardsData ~ data:', data);
    dispatch(levelsActions.setCam(prepareCam(data)));
  };

  /**
   * set video urls
   * @param data
   */
  const setVideoUrls = (data: any) => {
    //console.log('🚀 ~ setBoardsData ~ data:', data);
    dispatch(levelsActions.setVideoUrls(prepareVideoUrls(data)));
  };

  /**
   * logout
   */
  const userLogout = (__error: any) => {
    // console.log('🚀 ~ userLogout ~ error:', error);
    userService.logout();
    notify('Выход пользователя', 'Сокет вернул ошибку авторизации');
  };

  return null;
};
