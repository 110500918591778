import styled, { css } from 'styled-components';

export const Tr = styled.tr<{ priority?: IPriority }>`
  &.row-click {
    cursor: pointer;
  }
  ${(props) =>
    props.priority &&
    css`
      td:first-child {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 4px;
          top: 50%;
          margin-top: -2px;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          background-color: ${() => {
            switch (props.priority) {
              case 'critical':
                return '#ff4747';
              case 'notification':
                return '#ff8322';
              case 'info':
                return '#569056';
              default:
                return 'transparent';
            }
          }};
        }
      }
    `}
`;

export const Cell = styled.td`
  &:first-of-type {
    min-width: 20ch;
  }
`;
