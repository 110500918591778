import { Content, Tab } from './tabs.styled';

export type ITab = {
  title: string;
  value: string;
};

export type TabsProps = {
  items: Array<ITab>;
  value: string;
  onChange: (value: string) => void;
};

export function Tabs(props: TabsProps) {
  const { items, value, onChange } = props;

  const changeTab = (value: string) => {
    onChange(value);
  };

  const tabs = items.map((tab, idx) => {
    return (
      <Tab key={idx} onClick={() => changeTab(tab.value)} $active={tab.value === value}>
        {tab.title}
      </Tab>
    );
  });

  return <Content>{tabs}</Content>;
}

export default Tabs;
