import { VideoPlayer } from './video-player';
import { Content } from './double-player.styled';

export type DoublePlayerProps = {
  cams: Array<ICamUrl>;
  rackId: string;
  height?: string;
};

export const DoublePlayer = (props: DoublePlayerProps) => {
  const { cams, rackId, ...rest } = props;
  if (!cams || !cams.length) {
    return null;
  }

  const cams1 = cams.slice(0, 1);
  const cams2 = cams.toSpliced(0, 1);  

  return (
    <Content>
      <VideoPlayer cams={cams1} rackId={rackId} {...rest} />
      <VideoPlayer cams={cams2} rackId={rackId} {...rest} />
    </Content>
  );
};
