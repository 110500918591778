import styled, { css } from 'styled-components';

export const Icon = styled.div`
  margin: 0 7px 0 0;
  & svg,
  & img {
    width: 14px;
    height: auto;
  }
`;

export const Content = styled.span`
  display: block;
  text-align: center;
`;

export const ButtonStyled = styled.button<{
  fullWidth?: boolean;
  color?: 'primary' | 'secondary' | 'black' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 0;
  cursor: pointer;
  color: #fff;
  transition: 0.15s all ease-out;
  background: #3ea5ff;
  box-shadow: 0 1px 1px 0 #1d4a7b;
  &:hover {
    background: #1e85df;
  }

  height: 28px;
  line-height: 1;
  padding: 0 12px;
  font-size: 14px;
  color: #eeeeee;
  border-radius: 5px;
  position: relative;

  &.working {
    cursor: not-allowed;
    background: #1e85df !important;
  }
  &.working::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6) url('/images/loader.svg') no-repeat 50% 50%;
    background-size: 25px auto;
    border-radius: 5px;
  }

  // clean
  &.clean{
    border: 0;
    padding: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    height: auto;
  }

  // fullwidth
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  // color secondary
  ${(props) =>
    props.color === 'secondary' &&
    css`
      background: #67707a;
      box-shadow: 0 1px 1px 0 #37404a;
      &:hover {
        background: #57606a;
      }
    `}

  // color black
  ${(props) =>
    props.color === 'black' &&
    css`
      background: transparent;
      border: 1px solid #67707a;
      box-shadow: 0 1px 1px 0 #17202a;
      &:hover {
        background: #57606a;
      }
    `}

  // color danger
  ${(props) =>
    props.color === 'danger' &&
    css`
      background: #f74747;
      border: 1px solid transparent;
      box-shadow: 0 1px 1px 0 #17202a;
      &:hover {
        background: #d72727;
      }
    `}
    
  // size lg
  ${(props) =>
    props.size === 'lg' &&
    css`
      border-radius: 5px;
      font-size: 18px;
      line-height: 0.75;
      height: 50px;
      ${Icon} {
        & img {
          width: 22px;
        }
      }
    `}

  // size md
  ${(props) =>
    props.size === 'md' &&
    css`
      height: 40px;

      ${Icon} {
        & img {
          width: 19px;
        }
      }
    `}

  // disabled
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
      &.clean{
        opacity: 0.8;
      }
    `}
`;
