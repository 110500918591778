import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 5px;
  background-color: #262b31;
  font-size: 14px;
`;

export const Tab = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  margin: 0 10px 0 0;
  position: relative;

  cursor: pointer;
  color: #1e85df;
  border-bottom: none;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 4px;
    background: transparent;
  }
  ${(props) =>
    props.$active &&
    css`
      color: #3ea5ff;
      &:after {
        background: #3ea5ff;
      }
    `}
`;
