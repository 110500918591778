import styled from 'styled-components';
import { Link } from "react-router-dom";
//import { css } from '@emotion/react';
//import { Link } from 'libs/layout';

export const StyledLogo = styled(Link)`
  width: 201px;
  height: 54px;
  cursor: pointer;
  transition: 0.2s all ease-in;
  text-decoration: none;
  display: flex;
  flex-grow: 0;
  & img {
    max-width: 100%;
    height: auto;
  }
  margin: 0 2rem 0 0;

  &:hover {
    text-decoration: none;
    opacity: .9;
  }
`;
