import styled, { css } from 'styled-components';

export const Input = styled.input<{ size?: string }>`
  padding: 0px 12px;
  min-width: 180px;
  border: 1px solid #bebebe;
  color: rgb(255, 255, 255);
  background-color: rgb(79, 87, 97);
  border-radius: 4px;
  font-size: 14px;
  height: 36px;
  width: 100%;

  ${(props) =>
    props.size === 'big' &&
    css`
      font-size: 18px;
      height: 50px;
    `}

  ${(props) =>
    props.color === 'black' &&
    css`
      border: 1px solid #888;
      color: #fff;
      background-color: transparent;
    `}
`;
