import { useAppSelector, INTERFACE_FEATURE_KEY, IInterfaceState } from 'app/store';
import { LayoutCommon } from 'libs/layout';

export type ILayoutBase = {
  children: JSX.Element;
};

export function LayoutBase(props: ILayoutBase) {
  const { children } = props;
  const data: IInterfaceState = useAppSelector((state: any) => state[INTERFACE_FEATURE_KEY]);

  return <LayoutCommon loading={data.loading}>{children}</LayoutCommon>;
}

export default LayoutBase;
