import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
`;

export const Icon = styled.div`
  width: 28px;
  height: 28px;
  border: solid 1px #24282c;
  border-radius: 50%;
  color: #94989c;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in 0.15s;
  &:hover {
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
  }
`;

export const Block = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 360px;  
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.9);
  top: 60px;
  left: -45px;
  padding: 15px;
  z-index: 9;
  transition: all ease-in 0.15s;
  &.enter{
    opacity: 1;
    transform: translateY(0);
  }
  &.leave, &.from{
    transform: translateY(60px);
    opacity: 0;
  }
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    top: -9px;
    left: 50px;
  }
`;
