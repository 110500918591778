import { Preloader } from './layout-common.styled';

export interface ILayoutCommon {
  children: JSX.Element;
  loading?: boolean;
}

export function LayoutCommon(props: ILayoutCommon) {
  const { children, loading } = props;
  return <Preloader className={loading ? 'loading' : ''}>{children}</Preloader>;
}

export default LayoutCommon;
