import { useState, useEffect } from 'react';
import moment from 'moment';

import { Place, Value } from './time.styled';

export function Time() {
  const [val, setVal] = useState(moment());
  useEffect(() => {
    let timer = setInterval(() => {
      setVal(moment());
    }, 1000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const cl = val.second() & 1 ? 'odd' : '';
  const t = val.format('HH<b>:</b>mm');

  return (
    <Place>
      <Value className={cl} dangerouslySetInnerHTML={{ __html: t }}></Value>
    </Place>
  );
}

export default Time;
