import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  display: block;

  select {
    width: 100%;
    font-size: 14px;
    padding: 0px 12px 0 9px;
    background-color: #4f5761;
    color: #ffffff;
    height: 36px;
    line-height: 36px;
    border: 1px solid #bebebe;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    text-indent: 0%;
  }
`;
