import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { StyleSheetManager } from 'styled-components';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import './reset.css';
import './index.css';
import { store } from 'app/store';
import App from 'app/app';

const container = document.getElementById('root');
const root = createRoot(container!);
const filterProps = (prop: string) => {
  return !['fullWidth', 'single', 'lg', 'error', 'absoluteErrors'].includes(prop);
};

root.render(
  <Provider store={store}>
    <StyleSheetManager shouldForwardProp={filterProps}>
      <ReactNotifications />
      <App />
    </StyleSheetManager>
  </Provider>
);
