import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin: 0 10px 0 30px;
  font-size: 15px;
  @media (max-width: 639px) {
    margin: 0 10px 0 10px;
    font-size: 14px;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
`;
