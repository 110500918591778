import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  margin: 1px 0 0 0;
  font-size: 12px;
  line-height: 1.1;
`;

export const Item = styled.div`
  padding: 10px;
  background: #30353b;
  border-bottom: 1px solid #242424;
  cursor: pointer;
  transition: 0.1s all ease-in;
  &:hover {
    background: #293037;
  }
`;
