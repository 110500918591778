export type CheckboxProps = any;

export function Checkbox(props: CheckboxProps) {
  const { label, ...rest } = props;
  return (
    <label>
      <input type="checkbox" {...rest} />
      {label}
    </label>
  );
}

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
