import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export const CALLS_FEATURE_KEY = 'calls';

import { deviceService, callService, userService } from 'libs/services';

export interface ICallsState {
  calls: IData;
}

export const initialCallsState: ICallsState = {
  calls: {},
};

const deleteCall = (calls: any, id: string) => {
  delete calls[id];
  callService.removeActiveCall(id);
};

export const callsSlice = createSlice({
  name: CALLS_FEATURE_KEY,
  initialState: initialCallsState,
  reducers: {
    /**
     * set call
     * @param state
     * @param action
     */
    setCall: (state, action: PayloadAction<IData>) => {
      const calls = { ...state.calls };
      calls[action.payload.source_inner_id] = { status: 'wait', ...action.payload };
      state.calls = calls;
    },

    /**
     * set call active
     * @param state
     * @param action
     */
    setCallActive: (state, action: PayloadAction<string>) => {
      let call = state.calls[action.payload];
      if (!call) {
        const devices = deviceService.getNodes();
        const node = devices[action.payload];
        call = {
          source: node.phone,
          source_inner_id: action.payload,
          id: node.id,
        };
      }
      state.calls[action.payload] = { ...call, status: 'active' };
    },

    /**
     * set calls active
     * @param state
     * @param action
     */
    setCallsActive: (state, action: PayloadAction<Array<any>>) => {
      const calls: IData = {};
      action.payload.forEach((item: any) => {
        calls[item.source_inner_id] = {
          source: item.source,
          source_inner_id: item.source_inner_id,
          id: item.id,
          status: 'active',
        };
      });
      state.calls = { ...state.calls, ...calls };
    },

    /**
     * drop call
     * @param state
     * @param action
     */
    dropCall: (state, action: PayloadAction<string>) => {
      deleteCall(state.calls, action.payload);
    },

    /**
     * set calls
     * @param state
     * @param action
     */
    setCalls: (state, action: PayloadAction<IData>) => {
      const calls = { ...state.calls };
      const data = action.payload;
      const rightCalls = userService.haveRight('mnemo.calls');
      if (!rightCalls) {
        return;
      }

      const map1: any = {};
      data.forEach((el: any) => {
        map1[el.source_inner_id] = el;
      });

      for (let key in calls) {
        if (!map1[key]) {
          deleteCall(state.calls, key);
        }
      }

      data.forEach((el: any) => {
        calls[el.source_inner_id] = { status: 'wait', ...el };
      });
    },
  },
});

export const callsReducer = callsSlice.reducer;
export const callsActions = callsSlice.actions;
