import { Store } from 'react-notifications-component';
import moment from 'moment';
import 'moment/locale/ru';

const errors: IErrors = {
  httpErrors: {
    ECONNABORTED: { ru: 'Соединение прекращено' },
    597: { ru: 'Неверный старый пароль' },
    598: { ru: 'При импорте возникли ошибки' },
    599: { ru: 'Неизвестная ошибка сервера' },
    400: { ru: 'Ошибка данных' },
    401: { ru: 'Неправильный логин или пароль' },
    403: { ru: 'Нет прав для выполнения операции' },
    404: { ru: 'Страница не найдена' },
    405: { ru: 'Метод не разрешен' },
    423: { ru: 'Данный файл уже импортировался' },
    500: { ru: 'Ошибка сервера' },
    600: { ru: 'Недостаточно средств на счету' },
  },
};

export function getRand(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const notify = (title: string, msg: string = '', other?: any) => {
  other = {
    ...{
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: { duration: 2000, onScreen: true },
    },
    ...other,
  };

  if (!title) {
    title = 'Error';
  }

  if (typeof msg !== 'string') {
    msg = JSON.stringify(msg);
  }

  if (!msg) {
    msg = 'Неизвестная ошибка';
  }

  const params = {
    ...other,
    title: title,
    message: msg,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismissable: { click: true },
  };

  //console.log('notify', params);
  Store.addNotification(params);
};

export const fakeWait = (counts: number) => {
  let k;
  const cnt = counts * 100000;
  for (let i = 0; i < cnt; i++) {
    k = i ** 9;
  }
  return k;
};

/**
 *
 * @param time ms
 * @returns
 */
export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(time), time);
  });

export const getError = (code: number, type: string, message: string) => {
  if (message) {
    return message;
  }

  if (errors[type] && errors[type][code]) {
    return errors[type][code].ru;
  }

  return code.toString();
};

/**
 * date time functions
 */
export const stringToDate = (time?: any, format = 'D MMMM YYYY') => {
  const out = moment(time);
  out.locale('ru');
  return out.format(format);
};
export const stringToDateTime = (time?: any, format = 'D MMM YYYY HH:mm:ss') => {
  return stringToDate(time, format);
};

export const unixToDate = (time: number, format = 'D MMMM YYYY HH:mm:ss') => {
  const out = moment.unix(time);
  out.locale('ru');
  return out.format(format);
};

/**
 * save shot from video stream
 * @returns
 */
let working = false;
export const saveImg = (camId: string) => {
  if (working) {
    return;
  }
  working = true;
  const c = document.createElement('canvas');
  const img: any = document.getElementById(camId);
  try {
    //img.crossOrigin = 'test';
    c.width = img.width;
    c.height = img.height;
    const ctx: any = c.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = c.toDataURL('image/png');
    //img.crossOrigin = undefined;
    const link = document.createElement('a');
    link.download = camId + '.png';
    link.href = dataURL;
    //link.click();
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
  } catch (e: any) {
    console.log('🚀 save image error', e.message);
  }
  working = false;
};

/**
 * find item from array
 * @param items
 * @param idx
 * @returns
 */
export const findArrayItem = (items: Array<any>, idx: any, fn = 'id') => {
  return items.find((el) => {
    return el[fn] === idx;
  });
};

/**
 * get window coord
 * @param n
 * @returns
 */
export const getWindowCoord = (n: number) => {
  const small = window.innerWidth < 640;
  const kx1 = small ? 15 : 50;
  const kx2 = small ? 15 : 70;
  const ky = small ? 100 : 40;
  const x = kx1 + n * kx2;
  const y = ky + n * kx2;
  return { x, y };
};

/**
 * get scale from size
 */
export const getScale = (size: ISize) => {
  if (!size.width || !size.height) {
    return 1;
  }
  const imgWidth = 1876;
  const imgHeight = 970;
  let scale = (size.width * 100) / imgWidth / 100;
  if (scale > 1) {
    scale = 1;
  }
  const dy = 100;
  if (size.height - dy < imgHeight * scale) {
    let scale2 = ((size.height - dy) * 100) / imgHeight / 100;
    if (scale2 < scale) {
      scale = scale2;
    }
  }
  return scale;
};

/**
 * parse select value
 * @param val
 * @returns
 */
export const parseSelectValue = (val: string) => {
  return (val === '-1' || !val) ? null : parseInt(val, 0);
};

/**
 * prepare params
 * @param data
 * @param params
 * @returns
 */
export const prepareParams = (data: any, params: { selectFields?: string[]; boolFields?: string[]; dateFields?: string[] }) => {
  const out = { ...data };
  params?.selectFields?.forEach((fn) => {
    if (out[fn] !== undefined) {
      out[fn] = parseSelectValue(out[fn]);
    }
  });
  params?.boolFields?.forEach((fn) => {
    if (out[fn] !== undefined) {
      out[fn] = out[fn] === 'true';
    }
  });
  params?.dateFields?.forEach((fn) => {
    if (out[fn] !== undefined) {
      out[fn] = out[fn].format();
    }
  });
  return out;
};
