import { ReactNode, useState, useMemo, useRef, useEffect } from 'react';

import { useOutsideClicker } from 'libs/hooks';
import { Container, Header, List, ListContainer, ListItem, ArrowDown } from './cselect-input.styled';

export interface ICSelectOption {
  title: ReactNode;
  value: string;
}

export type CSelectInputProps = {
  name: string;
  label: string;
  options: ICSelectOption[];
  className?: string;
  disabled?: boolean;
  default?: string;
  value?: string;
  onChange?: (value: string) => void;
  fullWidth?: boolean;
  error?: boolean;
};

/**
 * select
 * @param props
 * @returns
 */
export const CSelectInput = (props: CSelectInputProps) => {
  const { options, value, onChange, ...rest } = props;
  const id = props.name;

  /**
   * find option
   * @param {*} value
   * @returns
   */
  const findOption = (value?: string) => {
    const v = options.filter((el) => el.value === value);
    return v.length ? v[0] : null;
  };

  /**
   * current option
   */
  const current = useMemo(() => {
    return findOption(value);
  }, [value]);

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(current);

  /**
   * change option if value current changed
   */
  useEffect(() => {
    setSelectedOption(current);
  }, [value]);

  /**
   * toggler open
   * @returns `
   */
  const toggle = () => setOpen(!open);

  /**
   * select option
   * @param {*} value
   * @returns
   */
  const onOptionClicked = (value: string) => () => {
    const opt = findOption(value);
    setSelectedOption(opt);
    setOpen(false);
    onChange && onChange(value);
  };

  /**
   * close on click outside
   */
  const ref = useRef(null);
  useOutsideClicker(ref, () => {
    setOpen(false);
  });

  return (
    <div ref={ref}>
      <Container {...rest}>
        <Header onClick={toggle} className={selectedOption?.value}>
          {selectedOption?.title || ''}
          <input type="hidden" id={id} />
          <ArrowDown $active={open} />
        </Header>
        {open && (
          <ListContainer>
            <List>
              {options.map((option) => {
                const value = option.value;
                let cl = value === selectedOption?.value ? 'active' : '';
                cl += ' ' + value;
                return (
                  <ListItem className={cl} onClick={onOptionClicked(value)} key={value}>
                    {option.title}
                  </ListItem>
                );
              })}
            </List>
          </ListContainer>
        )}
      </Container>
    </div>
  );
};
