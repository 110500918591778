import { config } from 'app/config';

/**
 * prepare zones from socket
 * @param data
 * @returns
 */
export const prepareZones = (data: any) => {
  return (
    data
      // .filter((el: any) => el.name !== 'S1')
      .map((el: any) => {
        const out: IZoneState = {
          zoneId: el.zone_id,
          current: el.current,
          maximum: el.maximum,
          name: el.name,
        };
        return out;
      })
  );
};

/**
 * prepare cam from socket
 * @param data
 * @returns
 */
export const prepareCam = (data: any): ICamState => {
  return { rackId: data.target, mode: data.mode, recognition: data.recognition };
};

/**
 * prepare video urls from socket
 * @param data
 * @returns
 */
export const prepareVideoUrls = (data: any) => {
  const urls: Array<ICamUrl> = [];
  Object.keys(data).forEach((key) => {
    let url = data[key];
    if (url && key.indexOf('cam') !== -1) {
      if (config.localhost) {
        url = url.replace('/getVideo/', '');
      }
      urls.push({ id: key, url });
    }
  });

  return { rackId: data.target, urls };
};
