import { useRef, useState } from 'react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';

import { Modal } from 'libs/ui';
import { useWindowSize } from 'libs/hooks';
import { windowService } from 'libs/services';

export type WindowProps = {
  children: any;
  title: string;
  close: () => void;
  onChangeCoord?: (x: number, y: number) => void;
  positionOffset?: { x: number; y: number };
  slim?: boolean;
};

export const Window = (props: WindowProps) => {
  const { children, onChangeCoord, title, close, positionOffset, slim } = props;
  const nodeRef = useRef(null);
  const [currentZIndex, setCurrentZIndex] = useState(windowService.updateLastZIndex());
  // window size
  const wSize = useWindowSize();
  if (!wSize) {
    return null;
  }

  const handleZindex = (element: any) => {
    const newVal = windowService.updateLastZIndex();
    element.style.zIndex = currentZIndex;
    setCurrentZIndex(newVal);
  };

  const setCurrent = () => {
    handleZindex(nodeRef.current);
  };

  const savePosition = (__event: DraggableEvent, data: DraggableData) => {
    const { x, y } = data;
    onChangeCoord && onChangeCoord(x, y);
  };

  const limit = 40;
  const bounds = {
    left: limit,
    top: limit,
    right: (wSize.width ?? 1600) - limit * 4,
    bottom: (wSize.height ?? 1280) - limit * 4,
  };

  return (
    <Draggable handle=".drag-handle" nodeRef={nodeRef} onStart={setCurrent} defaultPosition={positionOffset} onStop={savePosition} bounds={bounds}>
      <div ref={nodeRef} style={{ zIndex: currentZIndex }}>
        <Modal className="drag-handle" title={title} close={close} slim={slim}>
          {children}
        </Modal>
      </div>
    </Draggable>
  );
};
