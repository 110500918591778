import { flexRender, Row } from '@tanstack/react-table';
import { Cell, Tr } from './table-row.styled';

export type TableRowProps = {
  row: Row<any>;
  rowClick?: any;
  onClick?: any;
  className?: string;
  priorityColumn?: string;
  rowVirtualizer?: any;
};

export const TableRow = ({ row, priorityColumn, rowVirtualizer, ...rest }: TableRowProps) => {
  const cells = row.getVisibleCells();

  // row params
  const other: any = { ...rest };
  if (other.rowClick) {
    other.onClick = (event: any) => {
      event.stopPropagation();
      rest.rowClick(row.original);
    };
    other.className = 'row-click';
    delete other.rowClick;
  }

  if (priorityColumn) {
    other.priority = row.original[priorityColumn];
  }

  // other.ref = (node: any) => rowVirtualizer.measureElement(node);

  return (
    <Tr {...other}>
      {cells.map((cell: any) => {
        const cellProps = {
          key: cell.id,
          style: {
            width: cell.column.getSize(),
          },
        };
        return <Cell {...cellProps}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Cell>;
      })}
    </Tr>
  );
};
