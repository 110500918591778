import { Button, SimpleModal, SimpleModalButtons, SimpleModalTitle } from 'libs/ui';
import { Content } from './confirm.styled';

export type ConfirmProps = {
  title: string;
  children?: any;
  close: () => void;
  closeTitle?: string;
  action?: () => void;
  actionTitle?: string;
  actionDisabled?: boolean;
  last?: number;
  className?: string;
};

export const Confirm = (props: ConfirmProps) => {
  const { className, title, children, close, action, closeTitle, actionTitle, actionDisabled } = props;

  const handleCancel = () => {
    close();
  };

  const handleAction = () => {
    action && !actionDisabled && action();
  };

  const actionOther: any = {};
  if (actionDisabled) {
    actionOther.disabled = true;
  }

  const cl = 'confirm ' + className ?? '';

  return (
    <SimpleModal className={cl}>
      <SimpleModalTitle>{title}</SimpleModalTitle>
      <Content>{children}</Content>
      <SimpleModalButtons>
        <Button onClick={handleCancel}>{closeTitle}</Button>
        {actionTitle && (
          <Button color="danger" onClick={handleAction} {...actionOther}>
            {actionTitle}
          </Button>
        )}
      </SimpleModalButtons>
    </SimpleModal>
  );
};

Confirm.defaultProps = {
  closeTitle: 'Отмена',
};
