import { User, Time } from 'libs/widgets';
import { AppBar, Toolbar, Left, Right } from './header.styled';

const Header = () => {
  return (
    <AppBar>
      <Toolbar>
        <Left>
          <Time />
        </Left>
        <Right>
          <User />
        </Right>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
