import { Selector } from './video-selector.styled';
import { Video } from './video';

export type IVideoSelector = {
  cams: Array<ICamUrl>;
  rackId: string;
  createtime: string;
  selectedCamId: string;
  selectCam: (camId: string) => void;
};

export const VideoSelector = (props: IVideoSelector) => {
  const { cams, rackId, createtime, selectedCamId, selectCam } = props;

  const getClassName = (active: boolean) => {
    return active ? 'active' : 'inactive';
  };

  /**
   * get one video
   * @param cam
   * @returns
   */
  const getVideo = (cam: ICamUrl) => {
    const cl = getClassName(selectedCamId === cam.id);
    const id = rackId + '_' + cam.id;
    const url = cam.url + '?ct=' + createtime;

    return <Video key={id} camId={cam.id} id={id} createtime={createtime} className={cl} src={url} func={selectCam} />;
  };

  /**
   * get videos el
   * @returns
   */
  const getVideos = () => {
    return cams.map((cam) => {
      return getVideo(cam);
    });
  };

  if (cams.length < 2) {
    return null;
  }
  const videos = getVideos();
  return <Selector>{videos}</Selector>;
};
