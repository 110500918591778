import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
export { shallowEqual } from 'react-redux';

import { USER_FEATURE_KEY, userReducer } from './user/user.slice';
import { INTERFACE_FEATURE_KEY, interfaceReducer } from './interface/interface.slice';
import { BOARDS_FEATURE_KEY, boardsReducer } from './boards/boards.slice';
import { LEVELS_FEATURE_KEY, levelsReducer } from './levels/levels.slice';
import { EVENTS_FEATURE_KEY, eventsReducer } from './events/events.slice';
import { CALLS_FEATURE_KEY, callsReducer } from './calls/calls.slice';
import { RACKS_FEATURE_KEY, racksReducer } from './racks/racks.slice';

export const store = configureStore({
  reducer: {
    [USER_FEATURE_KEY]: userReducer,
    [INTERFACE_FEATURE_KEY]: interfaceReducer,
    [BOARDS_FEATURE_KEY]: boardsReducer,
    [LEVELS_FEATURE_KEY]: levelsReducer,
    [EVENTS_FEATURE_KEY]: eventsReducer,
    [CALLS_FEATURE_KEY]: callsReducer,
    [RACKS_FEATURE_KEY]: racksReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
