import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const LEVELS_FEATURE_KEY = 'levels';

export interface ILevelsSliceState {
  cams: Record<string, ICamState>;
  places: Record<string, IPlaceState>;
  zones: Array<IZoneState>;
  zonesMap: Record<string, IZoneState>;
  // levels: Array<ILevelState>;
  levelsMap: ILevelsState;
  occupied: Array<string>;
  occupiedMap: Record<string, string>;
}

export const initialLevelsState: ILevelsSliceState = {
  cams: {},
  places: {},
  zones: [],
  zonesMap: {},
  // levels: [],
  levelsMap: {},
  occupied: [],
  occupiedMap: {},
};

/**
 * get places state from cams
 * @param cams
 * @returns
 */
const updatePlaces = (cams: any) => {
  const out: Record<string, IPlaceState> = {};
  Object.keys(cams).forEach((key: string) => {
    const cam = cams[key];
    if (cam.mode !== 'offline' && cam.recognition !== undefined) {
      Object.keys(cam.recognition).forEach((rkey: string) => {
        out[rkey] = { ...cam.recognition[rkey], rackId: key };
      });
    }
  });
  //console.log('🚀 ~ Object.keys ~ out:', out);
  return out;
};

export const levelsSlice = createSlice({
  name: LEVELS_FEATURE_KEY,
  initialState: initialLevelsState,
  reducers: {
    /**
     * set zones state from socket
     * @param state
     * @param action
     */
    setZones: (state, action: PayloadAction<{ occupied: Array<string>; zones: any; levels: any }>) => {
      state.zones = action.payload.zones;
      state.zones.forEach((el) => {
        state.zonesMap[el.zoneId] = el;
      });

      // state.levels = action.payload.levels;
      const levelsMap: ILevelsState = {};
      action.payload.levels?.forEach((el: any) => {
        levelsMap[el.level.toString()] = { level: el.level.toString(), current: el.occupied, maximum: el.total };
      });
      state.levelsMap = levelsMap;      

      const out: any = {};
      state.occupied = action.payload.occupied;
      state.occupied?.forEach((el) => {
        out[el] = el;
      });
      state.occupiedMap = out;
    },

    setCam: (state, action: PayloadAction<ICamState>) => {
      //console.log('🚀 ~ action.payload:', action.payload);
      if (state.cams[action.payload.rackId] === undefined) {
        state.cams[action.payload.rackId] = action.payload;
      } else {
        state.cams[action.payload.rackId] = { ...state.cams[action.payload.rackId], ...action.payload };
      }
      state.places = updatePlaces(state.cams);
    },

    setVideoUrls: (state, action: PayloadAction<{ rackId: string; urls: Array<ICamUrl> }>) => {
      if (state.cams[action.payload.rackId] !== undefined) {
        state.cams[action.payload.rackId].urls = action.payload.urls;
      }
    },
  },
});

export const levelsReducer = levelsSlice.reducer;
export const levelsActions = levelsSlice.actions;

export const selectZonesStatus = (zones: Array<any>) => (state: any) => {
  const out: any = {};
  const zonesStatuses = state[LEVELS_FEATURE_KEY].zonesMap;
  zones?.forEach((el) => {
    out[el.zoneId] = zonesStatuses[el.zoneId];
  });
  return out;
};

export const selectLevelsStatus = () => (state: any) => {
  return state[LEVELS_FEATURE_KEY].levelsMap;
};

export const isOccupied = (id: string) => (state: any) => {
  return state[LEVELS_FEATURE_KEY].occupiedMap[id] !== undefined;
};
