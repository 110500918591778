import { ChangeEventHandler, ReactNode } from 'react';
import { Content } from './select-input.styled';

export interface IOption {
  title: ReactNode;
  value: string;
}

export type SelectInputProps = {
  name: string;
  className?: string;
  options?: IOption[];
  disabled?: boolean;
  label: string;
  default?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement> | undefined;
  fullWidth?: boolean;
  error?: boolean;
  setValue?: any;
};

/**
 * select
 * @param props
 * @returns
 */
export const SelectInput = (props: SelectInputProps) => {
  const { options, fullWidth, error, setValue, ...rest } = props;
  const id = props.name;

  return (
    <Content>
      <select id={id} {...rest}>
        {options &&
          options.map((el, idx) => (
            <option key={idx} value={el.value}>
              {el.title}
            </option>
          ))}
      </select>
    </Content>
  );
};
