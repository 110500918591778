import { useAppSelector, getUserName } from 'app/store';
import { userService } from 'libs/services';
import { ExitIcon, MenuIcon } from 'libs/icons';
import { /*SearchPlate, */ Button, WindowButton } from 'libs/ui';

import { Content, Name } from './user.styled';

export function User() {
  const userName = useAppSelector(getUserName());

  if (!userName) {
    return null;
  }

  /**
   * logout
   */
  const logout = () => {
    userService.logout();
  };

  return (
    <Content>
      {/* <WindowButton window="sip" id="sip" color="black" icon={<MenuIcon />}>
        SIP номер
      </WindowButton> */}
      {/*
      <Button color="black" onClick={showSearch} icon={<SearchIcon />}>
        Поиск
        {open && (
          <Popup>
            <SearchPlate />
          </Popup>
        )}
        </Button>*/}
      {/*<SearchPlate />*/}
      <Name>{userName}</Name>
      <Button onClick={logout} icon={<ExitIcon />}>
        Выйти
      </Button>
    </Content>
  );
}

export default User;
