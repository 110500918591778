import styled from 'styled-components';

export const Place = styled.div`
  position: relative;
  height: 100%;
  img {
    max-height: auto;
    width: 100%;
  }
  &.main{
    width: 100%;
  }
`;
