import styled from 'styled-components';

export const AppBar = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  background: #30353b; //1e2227;
  box-shadow: 0 5px 15px rgba(110, 110, 110, 0.1);
  padding: 10px;
  z-index: 100;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Left = styled.div`
  margin: 0 1rem 0 0;
`;

export const Right = styled.div``;
