import styled from 'styled-components';

export const Place = styled.div<{ height: string }>`
  display: block;
  position: relative;
  background: #444444;
  border-radius: 2px;
  height: ${(props) => props.height};
  overflow: hidden;
`;

export const Refresh = styled.button`
  position: absolute;
  right: 10px;
  top: 9px;
  border: 0;
  z-index: 15;
  border-radius: 3px;
  width: 34px;
  height: 34px;
  background: rgba(51, 51, 51, 0.6) url('/images/rebut.png') no-repeat 50% 50%;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #111;
  }
`;

export const Save = styled(Refresh)`
  background-color: rgba(170, 170, 170, 0.6);
  background-image: url('/images/takefoto.png');
  background-size: 16px auto;
  right: auto;
  left: 10px;
  &:hover {
    background-color: #eee;
  }
`;

export const Videos = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  //z-index: 10;
`;
