import { Content, Place, SimpleModalButtons, SimpleModalTitle } from './simple-modal.styled';

export { SimpleModalButtons, SimpleModalTitle };

export type SimpleModalProps = {
  children: any;
  className?: string;
};

export const SimpleModal = (props: SimpleModalProps) => {
  const { children, ...rest } = props;

  return (
    <Place onClick={(event) => event.stopPropagation()} {...rest}>
      <Content>{children}</Content>
    </Place>
  );
};
