import { Label } from './refresh-filter.styled';

export type RefreshFilterProps = {
  value: boolean;
  change: (e: any) => void;
  title: string;
};

export const RefreshFilter = (props: RefreshFilterProps) => {
  const { value, change, title } = props;

  return (
    <Label>
      <input type="checkbox" checked={value} onChange={change} />
      <span>{title}</span>
    </Label>
  );
};

RefreshFilter.defaultProps = {
  title: 'Автоообновление',
};
