import { Content, Label } from './radio-group.styled';

export type RadioGroupProps = {
  choices: Array<{ value: string; title: string }>;
  name: string;
  value: string;
  onChange: (value: string) => void;
};

export const RadioGroup = (props: RadioGroupProps) => {
  const { choices, name, value, onChange, ...rest } = props;

  const changeValue = (event: any) => {
    const val = event.target.value;
    // console.log('🚀 ~ changeValue ~ val:', val);
    onChange(val);
  };

  const choicesEl = choices.map((el, idx) => {
    return (
      <Label key={idx}>
        <input type="radio" name={name} value={el.value} defaultChecked={el.value === value} onClick={changeValue} />
        {el.title}
      </Label>
    );
  });

  return <Content>{choicesEl}</Content>;
};
