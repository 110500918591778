//import { hmac } from 'libs/common';

export type IUserServiceActions = {
  // notify: any;
  logout: () => void;
  setUser: (data: any) => void;
};

export type IUserService = {
  api: any;
  actions: IUserServiceActions;
};

/**
 * service for interaction with user api
 *
 * @class UserService
 */
export class UserService {
  api: any;
  actions: any;
  role: string;
  id: string = '';
  sip: string = '';
  permissions: Record<string, boolean> = {};
  rightLogged: Record<string, boolean> = {};
  dictionaries: Record<string, Record<string, string>> = {
    vehicle_category_map: {},
    vehicle_class_map: {},
  };
  /**
   *
   * @param {*} param0 = {
   *  api - backend api
   *  actions - notify, logout, setUser
   * }
   */
  constructor({ api, actions }: IUserService) {
    this.api = api;
    this.actions = actions;

    // get current state
    this.role = '';
  }

  getUserId() {
    return this.id;
  }

  /**
   * 1. Check user token
   */
  async checkToken() {
    // if no token return
    if (!this.api.getToken()) {
      this.actions.logout();
      return Promise.resolve();
    }

    // get info
    return await this.userInfo();
  }

  /**
   * get user info
   * @returns
   */
  async userInfo() {
    return await this.api.call('users.info').then((data: any) => {
      if (data) {
        this.id = data.config.id ? data.config.id : data.id;
        this.role = data.config.role_id ? data.config.role_id : 'admin';
        this.permissions = data.config.permissions ? data.config.permissions : data.permissions;
        this.sip = data.config.sip_number;
        Object.keys(this.dictionaries).forEach((dict) => {
          this.dictionaries[dict] = data.config[dict] ?? {};
        });
      }
      this.actions.setUser(data);
      return data;
    });
  }

  /**
   * Login request
   * @param params
   * @returns promise
   */
  async login(params: { username: string; password: string }) {
    let err = false;
    let errResult = '';
    await this.api.login(params).catch((result: any) => {
      err = true;
      errResult = result;
      //console.log('🚀 ~ login ~ result', result);
    });

    if (err) {
      return { error: errResult };
    }

    // get info
    return await this.userInfo();
  }

  /**
   * logout request
   */
  async logout() {
    this.actions.logout();
  }

  /**
   * user have right
   * @param right
   * @returns
   */
  haveRight(right: string) {
    if (!this.permissions) {
      return;
    }
    if (!this.rightLogged[right]) {
      this.rightLogged[right] = true;
    }
    return this.permissions[right];
  }

  /**
   * is operator check
   * @returns
   */
  isOperator() {
    return false; // user.info.access_level === 1
  }

  /**
   * get dict
   * @param dict
   * @returns
   */
  getDict(dict: string) {
    return this.dictionaries[dict];
  }

  /**
   * get sip number
   * @returns
   */
  getSip() {
    return this.sip;
  }

  /**
   * set sip number
   * @returns
   */
  setSip(sip: string) {
    this.sip = sip;
  }
}
