import userService from '../user-service/user-service';

/**
 * call service
 *
 * @summary store calls
 */

class CallService {
  // new
  calls: IData = {};

  constructor() {
    this.loadActiveCalls();
  }

  /**
   * load active calls
   * @returns
   */
  loadActiveCalls() {
    if (!userService.haveRight('mnemo.calls')) {
      return false;
    }

    let out = {};
    let calls: any = sessionStorage.getItem('activecalls');
    if (calls) {
      try {
        calls = JSON.parse(calls);
      } catch (e) {
        calls = {};
      }
      out = calls;
    }
    this.calls = out;
  }

  /**
   * get calls
   * @returns
   */
  getActiveCalls() {
    return this.calls;
  }

  /**
   * get calls cnt
   * @returns
   */
  getActiveCallsCnt() {
    return Object.keys(this.calls).length;
  }

  /**
   * save active calls
   */
  saveActiveCalls = () => {
    this.getActiveCalls();
    sessionStorage.setItem('activecalls', JSON.stringify(this.calls));
  };

  /**
   * add call
   * @param id
   */
  addActiveCall(id: string) {
    this.calls[id] = 1;
    this.saveActiveCalls();
  }

  /**
   * remove call
   * @param id
   */
  removeActiveCall = (id: string) => {
    if (this.calls[id]) {
      delete this.calls[id];
      this.saveActiveCalls();
    }
  };

  /**
   * is active call
   * @param id
   * @returns
   */
  isActiveCall(id: string) {
    return !!this.calls[id];
  }

  /**
   * clear calls
   */
  clearActiveCalls() {
    this.calls = {};
    this.saveActiveCalls();
  }

  /**
   * is we need show calls widget
   * @param callsObj
   * @returns
   */
  isActiveCallWidgetVisible(callsObj: IData) {
    if (!userService.haveRight('mnemo.calls')) {
      return false;
    }
    if (!this.getActiveCallsCnt()) {
      return false;
    }
    let r = false;
    for (let key in callsObj) {
      if (this.isActiveCall(key)) {
        r = true;
      }
    }
    return r;
  }
}

export const callService = new CallService();
