import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const BOARDS_FEATURE_KEY = 'boards';

export interface IBoardsState {
  boardsMap: Record<IBoardState['zoneId'], IBoardState>;
}

export const initialBoardsState: IBoardsState = {
  boardsMap: {},
};

export const boardsSlice = createSlice({
  name: BOARDS_FEATURE_KEY,
  initialState: initialBoardsState,
  reducers: {
    /**
     * set zones state from socket
     * @param state
     * @param action
     */
    setStatus: (state, action: PayloadAction<{ zones: IZoneState[] }>) => {
      const out: IBoardsState['boardsMap'] = {};
      action.payload?.zones?.forEach((el) => {
        out[el.zoneId] = el;
      });

      state.boardsMap = out;
    },

    /**
     * set single board status
     * @param state
     * @param action
     */
    setBoardStatus: (state, action: PayloadAction<{ zone: IZoneState }>) => {
      const data = action.payload.zone;
      if (data.zoneId) {
        state.boardsMap[data.zoneId] = data;
      }
    },
  },
});

export const boardsReducer = boardsSlice.reducer;
export const boardsActions = boardsSlice.actions;

export const getBoardCnt = (id: string) => (state: { [BOARDS_FEATURE_KEY]: IBoardsState }) => {
  const board = state[BOARDS_FEATURE_KEY].boardsMap[id];
  if (!board) {
    return 0;
  }
  return board.maximum - board.current;
};

export const getBoardMax = (id: string) => (state: { [BOARDS_FEATURE_KEY]: IBoardsState }) => {
  const board = state[BOARDS_FEATURE_KEY].boardsMap[id];
  if (!board) {
    return 0;
  }
  return board.maximum;
};
