import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const RACKS_FEATURE_KEY = 'racks';

export interface IRacksState {
  racks: Record<string, IRackState>;
}

export const initialRacksState: IRacksState = {
  racks: {},
};

export const racksSlice = createSlice({
  name: RACKS_FEATURE_KEY,
  initialState: initialRacksState,
  reducers: {
    /**
     * unset rack
     * @param state
     * @param action
     */
    unsetRack: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      delete state.racks[id];
    },
    /**
     * set rack
     * @param state
     * @param action
     */
    setRack: (state, action: PayloadAction<IRackState>) => {
      const id = action.payload.target;
      if (state.racks[id] === undefined) {
        state.racks[id] = action.payload;
      } else {
        state.racks[id] = { ...state.racks[id], ...action.payload };
      }
    },
  },
});

export const racksReducer = racksSlice.reducer;
export const racksActions = racksSlice.actions;

export const getRackMode = (rackId: string) => (state: { [RACKS_FEATURE_KEY]: IRacksState }) => {
  return state[RACKS_FEATURE_KEY].racks[rackId]?.mode;
};

export const getRackState = (rackId: string) => (state: { [RACKS_FEATURE_KEY]: IRacksState }) => {
  return state[RACKS_FEATURE_KEY].racks[rackId];
};
