import axios, { AxiosInstance } from 'axios';

type ITransport = {
  apiUrl: string;
  logic: any;
  timeout: number;
};

//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
export class Transport {
  token: string = '';
  server: AxiosInstance;
  logic: any;

  /**
   * set interceptors
   */
  constructor({ apiUrl, logic, timeout = 50000 }: ITransport) {
    this.server = axios.create({
      baseURL: apiUrl,
      timeout: timeout,
      headers: {},
    });

    this.server.interceptors.request.use(logic.prepareRequest, logic.prepareError);
    this.server.interceptors.response.use(logic.prepareResponse, logic.prepareError);
    this.logic = logic;
  }

  logout = () => {
    this.logic.logout();
  };

  login = (params: any) => {
    return this.call(this.logic.loginMethod, params);
  };

  getToken = (): string => {
    return this.logic.getToken();
  };

  async call(method: string, params?: any, type = 'post') {
    switch (type) {
      default:
      case 'post':
        return await this.server.post(method, params);
      case 'put':
        return await this.server.put(method, params);
      case 'delete':
        return await this.server.delete(method, params);
      case 'get':
        return await this.server.get(method, params);
    }
  }

  async get(method: string, params: any) {
    return await this.call(method, { params: params }, 'get');
  }

  async post(method: string, params: any) {
    return await this.call(method, params);
  }

  async put(method: string, params: any) {
    return await this.call(method, params, 'put');
  }

  async delete(method: string, params: any) {
    return await this.call(method, params, 'delete');
  }
}
