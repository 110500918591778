import { useState, ImgHTMLAttributes } from 'react';

import { ImgStyled } from './img.styled';

export type ImgProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  title: string;
  afterError: () => void;
  className?: string;
};

export const Img = (props: ImgProps) => {
  const { src, title, afterError, ...rest } = props;

  const [alt, setAlt] = useState(title);
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    // setAlt(title);
    setLoading(false);
  };

  const onError = () => {
    setAlt('Ошибка загрузки');
    setLoading(false);
    afterError();
  };

  const cl = loading ? 'loading' : '';

  return <ImgStyled className={cl} src={src} alt={alt} title={alt} onLoad={onLoad} onError={onError} {...rest} />;
};
