/**
 * User service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { LocalStorage } from 'libs/common';
import { levelsService, deviceService } from 'libs/services';
import { store, userActions, interfaceActions } from 'app/store';
import { UserService } from './user-service.class';

const storage = new LocalStorage('api');

/**
 * set user info
 * @param data
 */
const setUser = (data: any) => {
  levelsService.init(data.config.objects, data.config.levels);
  deviceService.init(data.config.objects);
  store.dispatch(userActions.setUser(data));
};

const actions = {
  setUser,
  logout: () => {
    storage.clear();
    store.dispatch(interfaceActions.clean());
    store.dispatch(userActions.logout());
    
  },
};

export const userService = new UserService({ api, actions });
export default userService;
