import styled from 'styled-components';

export const UserContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-right: 40px;
  color: #231f20;
  font-weight: 500;
  @media (max-width: 639px) {
    margin-right: 15px;
    font-size: 14px;
  }
`;

export const Menu = styled.ul``;

export const MenuItem = styled.li`
`;

export const Divider = styled.div`
  border-bottom: 1px solid #999;
`;

export const IconButton = styled.div``;

export const ListItemIcon = styled.div``;

export const ListItemText = styled.div``;
