import styled from 'styled-components';

export const Content = styled.div`
  display: block;
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 15px;
  cursor: pointer;
  input {
    margin-right: 10px;
  }
`;
