import { Content, Label, Info } from './form-control.styled';

export type FormControlProps = {
  label?: string;
  id: string;
  children: any;
  className?: string;
  error?: boolean;
  info?: string;
  size: string;
  withoutLabel?: boolean;
};

export const FormControl = (props: FormControlProps) => {
  const { label, className, id, children, error, size, info, withoutLabel, ...rest } = props;
  let cl = 'form-control ' + (className ? className : '');
  if (size) {
    cl += ' ' + size;
  }
  return (
    <Content {...rest} $error={error} $size={size} className={cl}>
      {!withoutLabel && label && <Label htmlFor={id}>{label}</Label>}
      {children}
      {info && <Info>{info}</Info>}
    </Content>
  );
};

export default FormControl;
